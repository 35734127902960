import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import LayoutContainer from './components/Layout/layout_container';
import './App.css';
import {NotFound} from "./components/modules/util/not_found";
import Redirect from "./components/modules/util/redirect";
import Home from './components/modules/home/Home';

export default class App extends Component {
  render() {
    return (
        // Router has only one child component
        <BrowserRouter>
            <Switch>
                {/* <Route exact path="/" component={(props)=><Redirect {...props} loc="/search" />} /> */}
                <Route exact path="/" component={Home} />
                <Route exact path="/home/authenticate" component={(props)=><Redirect {...props} loc="/" />} />
                <Route exact path="/authenticate" component={(props)=><Redirect {...props} loc="/search" />} />
                <Route exact path="/search" component={LayoutContainer} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
  }
}
// /authenticate?tk=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTg0MDg0NDQ3MzEsImlkIjoiZmQ5c2NrNTZqdnJvMmhzciIsImFjYyI6IjVhaGFneGpqZ2Mzd2s1eiIsInN1YiI6ImFiaGF5LnZhc2hpc2h0aGFAaWJpc20uY29tIiwibmFtZSI6IkFiaGF5IFZhc2hpc2h0aGEiLCJyb2xlIjoiYWRtaW4iLCJvcmdhbml6YXRpb24iOiJIZW5rZWwiLCJwZXJtaXNzaW9ucyI6W3sidXNlcnMiOlt7ImdldEFsbFVzZXJzIjp0cnVlfSx7ImdldE9uZVVzZXIiOnRydWV9LHsiY3JlYXRlVXNlciI6dHJ1ZX0seyJ1cGRhdGVVc2VyIjp0cnVlfSx7ImRlbGV0ZVVzZXIiOnRydWV9XX0seyJ0YXNrcyI6W3siZ2V0QWxsVGFza3MiOnRydWV9LHsiZ2V0T25lVGFzayI6dHJ1ZX0seyJjcmVhdGVUYXNrIjp0cnVlfSx7InVwZGF0ZVRhc2siOnRydWV9LHsiZGVsZXRlVGFzayI6dHJ1ZX1dfSx7InRlYW1zIjpbeyJnZXRBbGxUZWFtcyI6dHJ1ZX0seyJnZXRPbmVUZWFtIjp0cnVlfSx7ImNyZWF0ZVRlYW0iOnRydWV9LHsidXBkYXRlVGVhbSI6dHJ1ZX0seyJkZWxldGVUZWFtIjp0cnVlfV19LHsidGVtcGxhdGVzIjpbeyJnZXRBbGxUZW1wbGF0ZXMiOnRydWV9LHsiZ2V0T25lVGVtcGxhdGUiOnRydWV9LHsiY3JlYXRlVGVtcGxhdGUiOnRydWV9LHsidXBkYXRlVGVtcGxhdGUiOnRydWV9LHsiZGVsZXRlVGVtcGxhdGUiOnRydWV9XX1dLCJ0ZWFtcyI6W3siaWQiOiJmZDlzY2o5dWszZWFwMHE5IiwibmFtZSI6IkF1c3RyYWxpYSJ9LHsiaWQiOiJmZDlzYzVnZWp1ODB3aWxjIiwibmFtZSI6IkluZGlhIn0seyJpZCI6ImZkOXNjNWdlanU4MHc5OGUiLCJuYW1lIjoiQ2hpbmEifSx7ImlkIjoiZmQ5c2M1Z2VqdTgwdndmZCIsIm5hbWUiOiJHZXJtYW55In0seyJpZCI6ImZkOXNjNWdlanU4MHRqcHoiLCJuYW1lIjoiVVNBIn0seyJpZCI6IjVhaGFibW5qZ29xb2h3eSIsIm5hbWUiOiJDcmVhdGl2ZSJ9LHsiaWQiOiI1YWhhYm1uamdvcW8zN24iLCJuYW1lIjoiQ29udGVudCJ9LHsiaWQiOiI1YWhhYm1uamdvcW5sdmQiLCJuYW1lIjoiR2xvYmFsIE1hcmNvbSJ9XX0.SbBgjnjzzu7kBCFLFKPVzpkEilKWGHfKGMvz6wneqQU