import React from "react";
import { Modal } from "antd";
import ModalEmailSignin from "./email_signin";

const EmailModal = (props) => {
    return(
        <Modal
            visible             =   {props.showModal}
            onOk                =   {() => props.changeModalVisibility(false)}
            footer              =   {false}
            closable            =   {false}
            destroyOnClose      =   {true}
            bodyStyle           =   {{height : "400px"}}
            width               =   {620}
            style               =   {{ top: 150 }}
        >
            <ModalEmailSignin
                loading                     =   {props.loading}
                microsoftLoginValidation    =   {props.microsoftLoginValidation}
                emailLoginValidation        =   {props.emailLoginValidation}
                emailSignupValidation       =   {props.emailSignupValidation}
                otpValidation       =   {props.otpValidation}
            />
        </Modal>
    );
}

export default EmailModal;