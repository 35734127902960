import React, { Component } from "react";
import { Avatar, Button, Col, Input, Modal, notification, Row } from "antd";

import Attachments from "../../util/Attachments";

import common_scripts from "../../common_functions/script";

class NewContentRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // to: "Abhay Vashishtha<abhay.vashishtha@ibism.com>",
            to: "Vanshika Chaudhary<vanshika.chaudhary@envigo.co.uk>",
            title: "",
            message: "",
            error: {},
            loading: false,
        };
    }

    handleClose = () => {
        this.setState({
            title: "",
            message: "",
            loading: false,
        });
        this.props.handleModal();
    };

    showNotification = (type, title, description, btn) => {
        notification[type]({
            message: title,
            description: description,
            btn,
        });
    };

    hasError = () => {
        let { title, message } = this.state;
        title = title.trim();
        message = message.trim();

        const error = {};

        if (!title) {
            error.title = "Please enter title";
        }

        if (!message) {
            error.message = "Please enter message";
        }

        this.setState({ error });
        return Object.keys(error).length;
    };

    onSend = () => {
        if (!this.hasError()) {
            const { file, showNotification } = this.props;
            let { title, message } = this.state;
            title = title.trim();
            message = message.trim();

            this.setState({ loading: true });
            const payload = {
                data: {
                    attributes: {
                        // to: "abhay.vashishtha@ibism.com",
                        to: "vanshika.chaudhary@envigo.co.uk",
                        title,
                        message,
                        attachment_ids: [file.id],
                        request_uid: file.request_uid
                    },
                }
            };
            common_scripts
                .raiseContentRequests(payload)
                .then((res) => {
                    console.log("Raise content requests: ", res.data);
                    if (res.data.success) {
                        // showNotification('success', res.data.message);
                        showNotification('success', "Your request has been successfully raised");
                    } else {
                        showNotification("error", "Error while creating New Content Request. Please try again");
                    }

                    this.handleClose();
                })
                .catch((err) => {
                    console.log("Raise content requests: ", err);
                    this.handleClose();
                    showNotification("error", "Error while creating New Content Request. Please try again");
                });
        }
    };

    handleChange = (name) => (event) => {
        const value = event.target.value;

        this.setState({
            [name]: value,
            error: {},
        });
    };

    _renderFooter = () => {
        const { user_details } = this.props;
        const creator = (user_details && user_details.attributes) || {};

        return (
            <Row type="flex" align="middle" justify="space-between">
                <Row type="flex" align="middle" justify="start">
                    <Avatar
                        style={{ margin: "0 5px" }}
                        src={creator.avatar_thumb}
                        alt={creator.name}
                        title={creator.name}
                        size={32}
                        icon="user"
                    />

                    <Col className="user-details ml15">
                        <Row className="f-14 bold" type="flex" justify="start">
                            {creator.name}
                        </Row>
                        <Row className="f-12" type="flex" justify="start">
                            {creator.email}
                        </Row>
                    </Col>
                </Row>

                <Button
                    type="primary-outline"
                    loading={this.state.loading}
                    onClick={this.onSend}
                >
                    Send
                </Button>
            </Row>
        );
    };

    render() {
        const { showModal, file } = this.props;
        const { to, title, message, error } = this.state;

        return (
            <Modal
                title={"New Content Request"}
                width={620}
                visible={showModal}
                onCancel={this.handleClose}
                destroyOnClose={true}
                footer={this._renderFooter()}
            >
                {/* To email start */}
                <Col>
                    <label className="label">To</label>

                    <Input
                        className="custom-input"
                        placeholder="To"
                        value={to}
                        size="large"
                    />
                </Col>
                {/* To email end */}

                {/* Title start */}
                <Col className="mt10">
                    <label className="label">Title</label>

                    <Input
                        className="custom-input"
                        placeholder="Title"
                        value={title}
                        onChange={this.handleChange("title")}
                        size="large"
                        autoFocus
                        allowClear
                    />
                    <Row className="error mt5">{error.title}</Row>
                </Col>
                {/* Title end */}

                {/* Message start */}
                <Col className="mt10">
                    <label className="label">Message</label>

                    <Input.TextArea
                        className="custom-input"
                        placeholder="Message"
                        value={message}
                        onChange={this.handleChange("message")}
                        size="large"
                        allowClear
                        rows={5}
                    />

                    <Row className="error mt5">{error.message}</Row>
                </Col>
                {/* Message end */}

                {/* Attachments start */}
                {file && (
                    <Col className="mt10">
                        <Attachments attachments={[file]} readOnly />
                    </Col>
                )}
                {/* Attachments end */}
            </Modal>
        );
    }
}

export default NewContentRequest;
