import React from "react";
import {
    Row,
    Col,
    Card,
    Spin,
    notification,
    Empty,
    Icon,
    Select,
    Typography,
    Tooltip,
} from "antd";
import axios from "axios";
import moment from "moment";

import common_scripts from "../../common_functions/script";
import FilePreviewContainer from "../../file_preview/file_preview_container";

import api_url from "../../common_functions/api_constants";
import NewContentRequest from "./NewContentRequest";
import { getTeamFlag, readableFileSize } from "../../util/common_functions";

const { Paragraph } = Typography;

const emptyStyle = {
    display: "flex",
    justifyContent: "center",
    height: "60vh",
    alignItems: "center",
};

const cardHeadStyle = {
    padding: 0,
    background: "inherit!important",
    minHeight: 0,
    height: 0,
};

export default class CardDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            showDrawer: false,
            drawerDetails: null,
            loaderPercentage: 0,
            activeFile: null,
            showContentRequestModal: false,
            activeFileRequest: null,
        };
        this.selectedIndexFile = 0; //initial index of file -- will change when we select a file
    }

    changeDrawerDetails = (val) => {
        this.setState({ drawerDetails: val });
    };

    showDrawer = (val) => {
        this.setState({ showDrawer: val });
        if (!val) {
            this.changeDrawerDetails(null);
        }
    };

    changeCardLoader = (val) => {
        this.setState({ showLoader: val });
    };

    setSelectedCardDetails = (file) => {
        //set drawer details
        this.showDrawer(true);
        this.changeDrawerDetails(file);
        // this.getFileDetailsFromTask(file);
    };

    loadMoreData = () => {
        const { onSearch, searchedData = {} } = this.props;
        onSearch(searchedData.meta.count);
    };

    showNotification = (type, title, description, btn) => {
        notification[type]({
            message: title,
            description: description,
            btn,
        });
    };

    download = (e, id, filename) => {
        if (e) e.stopPropagation();
        this.showNotification('success', "Downloading File");
        let _this = this;

        //API to increment download counter
        common_scripts
            .updateFileParams(`ids=${id}&download=true`)
            .then((rfd) => {
                console.log("success updating file download counter", rfd);
            })
            .catch((err) => {
                console.log("Error incrementing file download counter", err);
            });

        axios({
            method: "GET",
            url: `${api_url.TASK_URL}/get-attachment/${id}`,
            contentType: "application/doc; charset=utf-8",
            headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
            responseType: "arraybuffer",
            onDownloadProgress: function(progressEvent) {
                let percent = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                _this.setState({ loaderPercentage: percent });
            },
        })
            .then((response) => {
                let fileDownload = require("js-file-download");
                fileDownload(response.data, filename);
                _this.setState({ loaderPercentage: 0 });
            })
            .catch((err) => {
                console.log("Error downloading file", err);
                _this.showNotification(
                    "error",
                    "Unable to Download File",
                    err.message
                );
                _this.setState({ loaderPercentage: 0 });
            });
    };

    setActiveFile = (e, file, index) => {
        if (e) e.stopPropagation();
        this.setState({ activeFile: file });
        this.selectedIndexFile = index || 0;
    };

    setActiveFileRequest = (e, show = false, file = null) => {
        if (e) e.stopPropagation();
        show = typeof show === "boolean" && show;
        this.setState({
            showContentRequestModal: show,
            activeFileRequest: file,
        });
    };

    changeFilter = (filterType) => {
        let str = this.props.search.searchParams;
        let filterParams = this.props.search.searchParams;
        if (filterType === "downloaded") {
            let matchFound = str.search("&sort=download");
            if (matchFound === -1) {
                filterParams = "&sort=download&order=desc";
            }
        } else if (filterType === "viewed") {
            let matchFound = str.search("&sort=view");
            if (matchFound === -1) {
                filterParams = "&sort=view&order=desc";
            }
        } else if (filterType === "requested") {
            let matchFound = str.search("&sort=request");
            if (matchFound === -1) {
                filterParams = "&sort=request&order=desc";
            }
        }
        this.props.changeFilter(this.props.search.searchFilter, filterParams);
    };

    handleSortFilter = (e) => {
        let sortFilterParams = {};
        if (e === "today") {
            sortFilterParams.startDate = moment().startOf("day").utc().format();
            sortFilterParams.endDate = moment().endOf("day").utc().format();
        } else if (e === "last_week") {
            sortFilterParams.startDate = moment().subtract(1, "week").startOf("week").utc().format();
            sortFilterParams.endDate = moment().subtract(1, "week").endOf("week").utc().format();
        } else if (e === "last_month") {
            sortFilterParams.startDate = moment().subtract(1, "month").startOf("month").utc().format();
            sortFilterParams.endDate = moment().subtract(1, "month").endOf("month").utc().format();
        } else if (e === "last_year") {
            sortFilterParams.startDate = moment().subtract(1, "year").startOf("year").utc().format();
            sortFilterParams.endDate = moment().subtract(1, "year").endOf("year").utc().format();
        } else if (e === "ascending") {
            sortFilterParams.order = "ascending";
        } else if (e === "descending") {
            sortFilterParams.order = "descending";
        }

        this.props.changeFilter(sortFilterParams);
    };

    render() {
        const { searchedData = {}, user_details, searchResultsString } = this.props;
        const { meta = {}, data = [] } = searchedData;

        let files;
        if (!Array.isArray(searchedData) && data.length > 0) {
            files = data.map(
                (file, index) =>
                    file && (
                        <SingleFileDetail
                            key={file.id}
                            file={file}
                            index={index}
                            drawerDetails={this.state.drawerDetails}
                            setActiveFile={this.setActiveFile}
                            setSelectedCardDetails={this.setSelectedCardDetails}
                            setActiveFileRequest={this.setActiveFileRequest}
                            download={this.download}
                        />
                    )
            );
        } else {
            files = (
                <Row type="flex" align="middle" justify="center" className="fw">
                    <Empty
                        className="empty"
                        image="/assests/images/not_found.png"
                        style={emptyStyle}
                        description={""}
                    />
                </Row>
            );
        }

        return (
            <React.Fragment>
                <Col className="ml5 mr5">

                    <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}}>
                        <Col span={12}>
                            {
                                searchResultsString &&
                                <div className=" pull-left f-lg">
                                    <Paragraph ellipsis={{ rows: 2 }}>
                                        Showing results for  <span className="org-text">"{searchResultsString}"</span>
                                    </Paragraph>
                                </div>
                            }
                        </Col>

                        <Col span={12}>
                            <FilterButtons
                                changeFilter={this.changeFilter}
                                handleSortFilter={this.handleSortFilter}
                                searchParams={this.props.search.searchParams}
                            />

                            {
                                !!meta.total_count &&
                                <div className="pull-right">
                                    Showing <span className="org-text">{meta.count}</span> of <span className="org-text">{meta.total_count}</span>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} style={{marginTop : "5px"}}>
                        <Col span={24}>
                            <Spin spinning={this.state.showLoader || this.props.loading} size="large">
                                <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} style={{marginLeft : 'auto', width : '95%'}}>
                                    {files}
                                </Row>
                            </Spin>
                        </Col>
                    </Row>
                </Col>

                {this.state.activeFile && (
                    <FilePreviewContainer
                        files={
                            Array.isArray(data) &&
                            data.map((d) =>
                                Object.assign({}, d, { attributes: d })
                            )
                        }
                        activeFile={Object.assign({}, this.state.activeFile, {
                            attributes: this.state.activeFile,
                        })}
                        setActiveFile={this.setActiveFile}
                        type="tasks"
                        url="TASK_URL"
                        selectedIndexFile={this.selectedIndexFile}
                        showNotification={this.showNotification}
                    />
                )}

                <NewContentRequest
                    showModal={this.state.showContentRequestModal}
                    handleModal={this.setActiveFileRequest}
                    file={this.state.activeFileRequest}
                    user_details={user_details}
                    showMessageModal={this.props.showMessageModal}
                    showNotification={this.showNotification}
                />
            </React.Fragment>
        );
    }
}

export const getFileThumbImg = (file) => {
    let img_src,
        file_style = {};
    let thumb_location = file.thumb_location;
    let file_extension = file.extension;
    let filename = file.filename;

    if (thumb_location) {
        img_src = thumb_location;
        file_style = { height: 200 };
        return <img src={img_src} alt={filename} style={file_style} />;
    }

    //if thumb is not present
    img_src = "/assests/images";
    file_style = { width: "70px" };
    switch (file_extension.toLowerCase()) {
        case "pdf":
            img_src = `${img_src}/pdf.png`;
            break;
        case "rar":
            img_src = `${img_src}/rar.png`;
            break;
        case "zip":
            img_src = `${img_src}/zip.png`;
            break;
        case "ppt":
        case "pptx":
            img_src = `${img_src}/ppt.png`;
            break;
        case "xls":
        case "xlsx":
            img_src = `${img_src}/xls.png`;
            break;
        case "csv":
            img_src = `${img_src}/csv.png`;
            break;
        case "doc":
        case "docx":
        case "txt":
            img_src = `${img_src}/doc.png`;
            break;
        case "aac":
        case "mp3":
        case "m4a":
        case "ogg":
        case "wav":
        case "webm":
        case "wma":
            img_src = `${img_src}/audio.png`;
            break;
        case "dwg":
        case "dwl":
            img_src = `${img_src}/dwg.png`;
            break;
        case "png":
            img_src = `${img_src}/png.png`;
            break;
        case "jpg":
        case "jpeg":
            img_src = `${img_src}/jpg.png`;
            break;
        case "gif":
            img_src = `${img_src}/gif.png`;
            break;
        case "avi":
        case "flv":
        case "mov":
        case "mp4":
        case "m4v":
        case "mpg":
        case "mpeg":
            img_src = `${img_src}/video.png`;
            break;
        default:
            img_src = `${img_src}/doc.png`;
            break;
    }
    return (
        <div className="search-image">
            <img src={img_src} alt={filename} style={file_style} />
        </div>
    );
};

const FilterButtons = (props) => {
    return (
        <Row className="mt10 mb10" gutter={{ xs: 16, sm: 16, md: 8, lg: 8 }}>
            <Select
                className="pull-right"
                style={{ width: 150 }}
                size="large"
                defaultValue=""
                onChange={(e) => props.handleSortFilter(e)}
            >
                <Select.Option value="">Sort By</Select.Option>
                <Select.Option value="descending">Latest</Select.Option>
                <Select.Option value="ascending">Oldest</Select.Option>

                {/* <Select.Option value="today">Today</Select.Option>
                <Select.Option value="last_week">Last Week</Select.Option>
                <Select.Option value="last_month">Last Month</Select.Option>
                <Select.Option value="last_year">Last Year</Select.Option> */}
            </Select>
        </Row>
    );
};

const SingleFileDetail = (props) => {
    const { file = {}, index } = props;
    const file_cover = getFileThumbImg(file);
    const iconStyle = {
        fontSize: "14px",
        color: "#fff",
    };
    const extra = (
        <span
            style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                padding: "5px",
                background: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
            }}
        >
            <Icon
                type="form"
                title="Click to New Content Request"
                style={{ ...iconStyle }}
                className="mr5"
                onClick={(e) => props.setActiveFileRequest(e, true, file)}
            />

            <Icon
                type="eye"
                theme="filled"
                title="Click to Preview File"
                style={{ ...iconStyle }}
                className="mr5"
                onClick={(e) => props.setActiveFile(e, file, index)}
            />

            <Icon
                type="download"
                title="Click to Download File"
                style={{ ...iconStyle }}
                className="mr5"
                onClick={(e) => props.download(e, file.id, file.filename)}
            />
        </span>
    );

    let file_name = file.filename;
    const file_ext = file_name.substring(file_name.lastIndexOf(".") + 1);
    if (file_name.length > 25) {
        file_name = file_name.substring(0, 25) + "..." + file_ext;
    }

    const teamName = file && file.team && file.team.name.toLowerCase();
    const teamImage = getTeamFlag(file.team);

    return (
        <Col xs={24} sm={12} md={6} lg={4} xl={4} span={4} style={{margin:"15px", height: 300}}>
            <Card
                onClick={(e) => props.setActiveFile(e, file, index)}
                hoverable={true}
                bordered={true}
                headStyle={cardHeadStyle}
                extra={extra}
                style={{ borderRadius: 10, overflow: "hidden" }}
                bodyStyle={{ padding: 0 }}
                cover={file && file.thumb_location ? file_cover : null}
            />
            <Col className="pl5 pr5 mt10">
                <Row className="bold">
                    <Tooltip placement="bottomLeft" title={file.title}>
                        <Paragraph ellipsis={{ rows: 1 }}>
                            {file && file.title}
                        </Paragraph>
                    </Tooltip>
                </Row>
                <Row
                    className="f-11 primary-text mt5"
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    <Col className="bold">
                        {file.type} By {file.creator && file.creator.name && file.creator.name.split(" ")[0]}
                    </Col>
                    <Col>
                        {/* <span className="mr5">{file && file.team && file.team.name}</span> */}
                        <span className="mr5">
                            {
                                teamImage &&
                                <Tooltip title={`Team ${file.team.name}`}>
                                    <img src={`/assests/images/${teamImage}`} alt={teamName} style={{height: "12px", width: 16}} />
                                </Tooltip>
                            }
                        </span>
                        | <span className="mr5">
                            {readableFileSize(file.size)}
                         </span>
                        | <span style={{ textTransform: "uppercase" }}>{file_ext}</span>
                    </Col>
                </Row>

                <Row className="mt5 f-12">
                    <Tooltip placement="topLeft" title={file.description}>
                        <Paragraph ellipsis={{ rows: 2 }}>
                            {file.description}
                        </Paragraph>
                    </Tooltip>
                </Row>
            </Col>
        </Col>
    );
};
