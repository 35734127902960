import React from 'react';
import { Row, Button, Modal, Col, Card, Progress, Spin, Avatar, notification, Input, Divider, Badge, Tag, Icon} from 'antd';
import {getFileThumbImg} from "./card_details";
import common_scripts from "../common_functions/script";
import {ENV, CONTENT_WORKFLOW_ID, CONTENT_TEMPLATE_ID} from "../common_functions/api_constants";
import moment from 'moment';
import api_url from '../common_functions/api_constants';

export class FileIntiateRequestModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loader : false,
            threadName : "",
            fileTaskDetails : null
        }
    }

    componentDidMount(){
        if(this.props.drawerDetails){
            this.getFileDetailsFromTask(this.props.drawerDetails);
        }
    }

    getFileDetailsFromTask = (file) =>{
        common_scripts.getFileDetailsFromTask(file.id).then(res=>{
            console.log(res);
            this.setState({fileTaskDetails : res.data.data});
        }).catch(err=>{
            console.log('Error getting details of file from tasks ', err);
        })
    }

    // componentWillReceiveProps(nxtProps){
    //     this.setState({fileTaskDetails:nxtProps.fileTaskDetails})
    // }

    changeThreadName = (val) => {
        this.setState({threadName : val});
    }

    setButtonLoader = (val) => {
        this.setState({loader : val});
    }

    openTask = (taskId, noteId) => {
        notification.close();
        let path = `/app/create-request?id=${noteId}`;
        // let path = '/app/0/myDashboard';
        // switch(this.props.user_details.relationships.role.data.name){
        //     case 'Manager'      : path = "/app/1/myDashboard"; break;
        //     case 'Member'       : path = "/app/2/myDashboard"; break;
        //     case 'Freelancer'   : path = "/app/3/myDashboard"; break;
        //     default : path = "/app/0/myDashboard"; break;
        // }
        let url = `${ENV}/authenticate?tk=${localStorage.getItem('jwt')}&url=${path}&id=${taskId}`;
        window.open(url, '_self');//open the new page with redirection API
    }

    getTaskFields = (fields, file) => {
        let _fields = fields.map(f => {
            if(f.fid === api_url.FILE_FIELD_ID){//Content File
                f.value = [{id:file.id, ...file.attributes}];
            }
            return f;
        });
        return _fields;
    }

    triggerWf = (wfId, thread_name, firstTemplate, file) => {
        let _this = this;
        let user_details    = _this.props.user_details;
        let role            = user_details.relationships.role.data.name === "Administrator";
        
        //remove global marcom team if found
        // let teams;
        // if(user_details.relationships.teams.data && user_details.relationships.teams.data.length > 0){
        //     let _team = user_details.relationships.teams.data.filter(t=>t.name !== 'Global Marcom');
        //     if(_team && _team.length > 0){
        //         teams = _team[0].id;
        //     }
        // }
        
        let teams           = (user_details.relationships.teams.data && user_details.relationships.teams.data.length>0) ? user_details.relationships.teams.data[0].id : null;
        
        teams               = role ? '5ahabmnjgoqnlvd' : teams;

        //creating slots
        let slotSize        = "5";
        let currentDate     = moment().utc().format();
        let slots           = [{
                start : currentDate,
                end : moment(currentDate).add(+slotSize,'minute').utc().format()
            }];

        if(teams){
            //teams and users are array
            // let assignmentHint = common_scripts.getAssginmentHint([teams], [user_details.id], '15');
            //
            // common_scripts.assignmentHint(undefined, assignmentHint).then(resj => {
            //
            //     if(!resj.data.meta){
            //         console.log('_assignmentHintData', resj.data);
            //         _this.props.showNotification('error', 'Error getting assignment hint', resj.data.message);
            //         _this.setButtonLoader(false);
            //
            //     }else{
            //
            //         let _assignmentHintData = resj.data.data;
                    //get one template
                    common_scripts.getOneTemplate(undefined, firstTemplate).then(respd=>{

                        if(!respd.data.meta){
                            _this.props.showNotification('error', 'Error getting template details', respd.data.message);
                            _this.setButtonLoader(false);
                        }else{
                            let templateDetails = respd.data.data;
                            let _new_fields     = this.getTaskFields(templateDetails.attributes.fields, file);
                            //create task data
                            let taskObj = common_scripts.createTaskData(wfId, thread_name, "Started",templateDetails.id, templateDetails.attributes.name, _new_fields, 'soonest', undefined, thread_name, user_details.id, teams, slots);

                            common_scripts.createTask(undefined, taskObj).then(resd=>{
                                if(!resd.data.meta){
                                    console.log('Error creating task', resd.data);
                                    _this.props.showNotification('error', 'Error creating task', resd.data.message);
                                    _this.setButtonLoader(false);
                                }else{
                                    let taskId = resd.data.data.id;

                                    let notes = _this.props.user_notes;//complete data

                                    let newNote = {
                                        id: common_scripts.guid_generator(),
                                        note: resd.data.data.attributes.threadName,
                                        task: resd.data.data,
                                        wfId: resd.data.data.attributes.workflowId,
                                        threadId: resd.data.data.attributes.threadId,
                                        stage: 'open',
                                        render: true,
                                        wf_name : 'Content Request'
                                    };

                                    notes.attributes.notes.push(newNote);

                                    let notesObj = {
                                        data : {
                                            notes : notes.attributes.notes,
                                            render : true
                                        }
                                    };

                                    common_scripts.updateNotes(notesObj, notes.id).then(reshjkl=>{

                                        if(!reshjkl.data.meta){
                                            console.log('Error updating notes', reshjkl.data);
                                            _this.props.showNotification('error', 'Error creating task !', reshjkl.data.message);
                                            _this.setButtonLoader(false);
                                        }else {
                                            // let btn = <Button type="primary" size="small"
                                            //                   onClick={() => _this.openTask(taskId)}>View Task</Button>

                                            _this.props.showNotification('success', 'Content Raised Successfully', 'Content Request process has been initiated.');
                                            _this.setButtonLoader(false);
                                            _this.props.closeDrawer(false);
                                            _this.changeThreadName("");
                                            _this.props.changeDrawerDetails(null);

                                            //redirect to URL
                                            setTimeout(()=>{
                                                _this.openTask(taskId, newNote.id);
                                            }, 2000);
                                        }

                                    }).catch(err=>{
                                        console.log('error in notes creation', err);
                                        _this.props.showNotification('error', 'Error', err.message);
                                        _this.setButtonLoader(false);
                                    });
                                }
                            }).catch(err=>{
                                console.log('Error creating task', err);
                                _this.props.showNotification('error', 'Error', err.message);
                                _this.setButtonLoader(false);
                            })
                        }

                    }).catch(err => {
                        console.log('Error in assignment Hint', err);
                        _this.props.showNotification('error', 'Error', err.message);
                        _this.setButtonLoader(false);
                    });
            //     }
            //
            // }).catch(err => {
            //     console.log('Error in assignment Hint', err);
            //     _this.props.showNotification('error','Error getting assignment Hint', err.message);
            //     _this.setButtonLoader(false);
            // });

        }else{
            _this.props.showNotification('info','No Team Assigned', 'You cannot raise a request. Please ask your administrator to add you in a team.');
            _this.setButtonLoader(false);
        }
    }

    raiseRequest = (wfId, file) =>{
        let _this = this;

        //API to increment request counter
        common_scripts.updateFileParams(`ids=${file.id}&request=true`).then(rfd=>{
            console.log('success updating file request counter', rfd);
        }).catch(err=>{
            console.log('Error incrementing file request counter', err);
        })

        //get workflow from the given id
        _this.setButtonLoader(true);
        common_scripts.getOneWorkflow(undefined, wfId).then(res=>{
            if(!res.data.meta){
                console.log(res.data);
                _this.props.showNotification('Error', 'Error finding workflow', res.data.message);
                _this.setButtonLoader(false);
            }else{
                let workflowFound   = res.data.data;
                let firstTemplate   = workflowFound.attributes.nodes[0].template.template_id || CONTENT_TEMPLATE_ID;
                let threadName      = this.state.threadName.trim() === "" ? `Raise Request : ${file.attributes.filename}` : this.state.threadName;
                //call the main function to create Task
                _this.triggerWf(wfId, threadName, firstTemplate, file);
            }
        }).catch(err=>{
            console.log('Error in workflow',err);
            _this.props.showNotification('error', 'Error', err.message);
            _this.setButtonLoader(false);
        });
    }

    render(){
        let file_cover;
        if(this.props.drawerDetails){
            file_cover = getFileThumbImg(this.props.drawerDetails);
        }
        let file     = this.props.drawerDetails;
        let category = file.attributes.category && file.attributes.category.split('_');
        category     = category.join(' ');
        category     = category === 'video' || category === 'gif' ? category+'_1' : category;
        
        let content_type = {
            video : 0,
            gif : 0,
            infographic : 0,
            image_sets : 0,
            presentation : 0,
            emailer : 0,
            three_d_model : 0,
            localisation : 0
        }, target_su = {
            gm : 0,
            ia : 0,
            mro : 0,
            vrm : 0,
            fc : 0
        };
        
        if(this.state.fileTaskDetails){
            this.state.fileTaskDetails.forEach(fileTask=>{
                fileTask.content_type.filter(f=>f).forEach(c=>{
                    if(c.match('Video')){
                        content_type.video = content_type.video + 1;
                    }
                    if(c.match('GIF')){
                        content_type.gif = content_type.gif + 1;
                    }
                    if(c.match('Infographic')){
                        content_type.infographic = content_type.infographic + 1;
                    }
                    if(c.match('Image Sets')){
                        content_type.image_sets = content_type.image_sets + 1;
                    }
                    if(c.match('Presentation')){
                        content_type.presentation = content_type.presentation + 1;
                    }
                    if(c.match('Emailer')){
                        content_type.emailer = content_type.emailer + 1;
                    }
                    if(c.match('3D Model')){
                        content_type.three_d_model = content_type.three_d_model + 1;
                    }
                })
                fileTask.target_su.filter(f=>f).forEach(t=>{
                    if(t.match('GM')){
                        target_su.gm = target_su.gm + 1;
                    }
                    if(t.match('IA')){
                        target_su.ia = target_su.ia + 1;
                    }
                    if(t.match('MRO')){
                        target_su.mro = target_su.mro + 1;
                    }
                    if(t.match('VRM')){
                        target_su.vrm = target_su.vrm + 1;
                    }
                    if(t.match('FC')){
                        target_su.fc = target_su.fc + 1;
                    }
                })
            });
            console.log(target_su, content_type)
        }

        //Content Type
        let content_type_arr = [];
        if(content_type.video && content_type.video > 0){
            content_type_arr.push('Video');
        }
        if(content_type.gif && content_type.gif > 0){
            content_type_arr.push('GIF');
        }
        if(content_type.infographic && content_type.infographic > 0){
            content_type_arr.push('Infographic');
        }
        if(content_type.image_sets && content_type.image_sets > 0){
            content_type_arr.push('Image Sets');
        }
        if(content_type.presentation && content_type.presentation > 0){
            content_type_arr.push('Presentation');
        }
        if(content_type.emailer && content_type.emailer > 0){
            content_type_arr.push('Emailer');
        }
        if(content_type.three_d_model && content_type.three_d_model > 0){
            content_type_arr.push('3D Model');
        }
        if(content_type.localisation && content_type.localisation > 0){
            content_type_arr.push('Localisation');
        }

        //Target SU
        let target_su_arr = [];
        if(target_su.gm && target_su.gm > 0){
            target_su_arr.push('GM');
        }
        if(target_su.ia && target_su.ia > 0){
            target_su_arr.push('IA');
        }
        if(target_su.mro && target_su.mro > 0){
            target_su_arr.push('MRO');
        }
        if(target_su.vrm && target_su.vrm > 0){
            target_su_arr.push('VRM');
        }
        if(target_su.fc && target_su.fc > 0){
            target_su_arr.push('FC');
        }

        return <Modal
                    title           =  {
                                    <span>
                                        <span>Raise Request</span>
                                        <span style={{float:'right',marginRight:'35px'}}>
                                        {
                                            category === 'all' || category === 'source file' || category === 'feedback file'
                                            ?
                                                <span>
                                                    {
                                                        category === 'source file' &&
                                                            <Tag style={{transform: "translatez(0)"}} color="rgb(226, 0, 21)">Source File</Tag>
                                                    }
                                                    {
                                                        category === 'feedback file' &&
                                                            <span>
                                                                <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />
                                                                <span>Feedback</span>
                                                            </span>
                                                    }
                                                </span>
                                            :
                                                <span>
                                                    <img src={'assests/images/'+category+'.png'} alt={category} style={{width:'25px',marginRight:'5px'}}/>
                                                    <span style={{textTransform:'capitalize'}}>
                                                        {file.attributes.category.split('_').join(' ')}
                                                    </span>
                                                </span>
                                                    
                                        }
                                        </span>
                                    </span>
                    }
                    visible         =   {this.props.showDrawer}
                    onCancel        =   {e=>this.props.closeDrawer(false)}
                    footer          =   {false}
                    maskClosable    =   {false}
                    destroyOnClose  =   {true}
                    closable        =   {!this.state.loader ? true : false}
                    bodyStyle       =   {{height : "400px", overflow : 'hidden auto'}}
                    width           =   {740}
                    style           =   {{ top: 100 }}
                >
                {
                    file &&
                        <Spin spinning={this.state.loader}>
                            <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} type="flex" justify="center">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} span={12} className="modal-content-body center" style={{width:"45%"}}>
                                    <Card
                                        hoverable   =   {true}
                                        bordered    =   {true}
                                        cover       =   {file.attributes && file.attributes.thumb_location ? file_cover : null}
                                    >
                                        {!(file.attributes && file.attributes.thumb_location) ? file_cover : null}
                                    </Card>
                                    <small>{file.attributes ? file.attributes.filename : file.filename}</small><br /><br />
                                    <div style={{textAlign : 'left'}}>
                                        {
                                            content_type_arr.length > 0 &&
                                                <div>
                                                    <span className='bold'>Content Type: </span>
                                                    <span>{content_type_arr.join()}</span>
                                                </div>
                                        }
                                        {
                                            target_su_arr.length > 0 &&
                                                <div>
                                                    <span className='bold'>Target SU: </span>
                                                    <span>{target_su_arr.join()}</span>
                                                </div>
                                        }
                                        <div className="file-tags">
                                            <span className="bold">Tags: </span>
                                            <span>
                                                {
                                                    file.attributes.tags.length > 0 ?
                                                        file.attributes.tags.map((t, index)=>{
                                                            return <Badge key={index} count={t} style={{ backgroundColor: '#949894' }} />
                                                        })
                                                    :
                                                    '-'
                                                }
                                            </span>
                                            {/* <span>{file.attributes.tags.length>0 ? file.attributes.tags.join() : '-'}</span> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} span={12} className="modal-content-bottom">
                                    <div>
                                        <Input type="text" placeholder="Name the request" required onBlur={e=>this.changeThreadName(e.target.value)} />
                                        <Button
                                            type        =   "danger"
                                            icon        =   "sliders"
                                            theme       =   "filled"
                                            block       =   {true}
                                            style       =   {{margin:"10px 0", display: "inline-block"}}
                                            onClick     =   {e=>this.raiseRequest(CONTENT_WORKFLOW_ID, file)}
                                        >Raise Request</Button>
                                    </div>
                                    <Divider style={{margin:"10px 0"}}>
                                        <Avatar size={25} shape="circle" style={{backgroundColor: 'rgb(138, 135, 133)' }}>OR</Avatar>
                                    </Divider>
                                    <Button
                                        type        =   "danger"
                                        icon        =   "download"
                                        block       =   {true}
                                        onClick     =   {e=>this.props.download(e, file.id, file.attributes.filename)}
                                    >Download File</Button>
                                    <br />
                                    {
                                        this.props.loaderPercentage > 0 &&
                                            <Progress percent={this.props.loaderPercentage} />
                                    }
                                </Col>
                            </Row>
                        </Spin>
                    }
                </Modal>
    }
}