import React from "react";
import { Row, Icon, Tooltip } from "antd";
// import axios from 'axios';
// import appUrl from '../../../constants/AppUrl';
import { readableFileSize } from "../common_functions";

import {
    Rar,
    Xml,
    // Mpg,
    Docx,
    Doc,
    Pdf,
    Ppt,
    Txt,
    M4v,
    Zip,
    Psd,
    Wav,
    Html,
    Java,
    Gif,
    Mp3,
    XLSX,
    Flv,
    Mp4,
    Xls,
    Blank,
} from "./svg";

import "./styles.css";

const imageFileTypes = ["image/gif", "image/jpeg", "image/png"];

class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.fileComp = React.createRef();
        const { uploadingFiles = [] } = this.props;
        this.state = {
            filesToExclude: uploadingFiles.reduce((result, file) => {
                if (file._id) {
                    result[file._id] = true;
                }
                return result;
            }, {}),
        };
    }

    togglePreview = (fileId) => {
        this.setState({ showPreviewOfFileId: fileId });
    };

    componentDidMount() {
        this.fileComp.current &&
            this.fileComp.current.addEventListener("keydown", (e) => {
                // press esc to close the preview
                if (e.keyCode === 27) {
                    this.togglePreview(null);
                }
            });
    }

    componentWillUnmount() {
        this.fileComp.current &&
            this.fileComp.current.removeEventListener(
                "keydown",
                this.togglePreview()
            );
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadingFiles !== prevProps.uploadingFiles) {
            this.setState({
                filesToExclude: this.props.uploadingFiles.reduce(
                    (result, file) => {
                        if (file._id) {
                            result[file._id] = true;
                        }
                        return result;
                    },
                    {}
                ),
            });
        }
    }

    getFileTypeIcon = (mimetype) => {
        switch (mimetype) {
            case "rar":
                return Rar;
            case "xml":
                return Xml;
            case "mp3":
                return Mp3;
            case "docs":
            case "doc":
                return Docx;
            case "pdf":
                return Pdf;
            case "ppt":
                return Ppt;
            case "txt":
                return Txt;
            case "m4v":
                return M4v;
            case "zip":
                return Zip;
            case "psd":
                return Psd;
            case "wav":
                return Wav;
            case "html":
                return Html;
            case "js":
                return Java;
            case "gif":
                return Gif;
            case "xlx":
                return XLSX;
            case "flv":
                return Flv;
            case "mp4":
            case "application/mp4":
                return Mp4;
            case "xls":
                return Xls;
            default:
                return Blank;
        }
    };

    render() {
        const { attachments = [] } = this.props;

        return (
            <Row className="taskFile wrapper">
                {attachments.map((attachment, index) => {
                    let { filename, extension } = attachment;
                    console.log("attachment", attachment);
                    filename = `${filename.substring(0, 10)}${filename.length >
                        10 && "..."}${extension}`;

                    return (
                        <div
                            className="attachment-container-non-image"
                            key={attachment._id}
                        >
                            <div style={{ display: "flex" }}>
                                <Icon
                                    component={this.getFileTypeIcon(extension)}
                                />
                                <div
                                    style={{ margin: "16px 0px" }}
                                    className="truncate"
                                >
                                    <Tooltip title={attachment.filename}>
                                        <div>{filename}</div>
                                    </Tooltip>
                                    <div>
                                        {readableFileSize(attachment.size)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Row>
        );
    }
}

export default Attachments;
