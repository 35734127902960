import React from 'react';

export default class DocumentFileContainer extends React.Component{

    render(){
        let {file} = this.props;
        return (
            <div style={{display: "grid",height: "100%"}}>
                <iframe
                    title       =   {file.attributes.filename}
                    src         =   {file.blobURL + '#toolbar=0&navpanes=0&scrollbar=0'}
                    frameBorder =   "0"
                    style       =   {{width:'100%',height:'500px'}}
                    scrolling   =   "no" >
                </iframe>
            </div>
        )
    }

}