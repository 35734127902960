import React from "react";
import { Layout, Row, Col, Input, Icon, Menu } from "antd";
import { ENV } from "../../common_functions/api_constants";
import { LoggedUserDetails } from "../../../Layout/layout_header";

export default class LayoutHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            contentType: "",
        };
    }

    getmyDashboardPath = () => {
        const { user } = this.props;
        const role = user && user.relationships.role.data.name;

        switch (role) {
            case "Manager":
                return "/app/1/myDashboard";
            case "Member":
                return "/app/2/myDashboard";
            case "Freelancer":
                return "/app/3/myDashboard";
            default:
                return "/app/0/myDashboard";
        }
    };

    onSearch = () => {
        const { onSearch } = this.props;
        const { searchText } = this.state;

        onSearch(searchText.trim())
        console.log("searchText", searchText);
    };

    handleChange = (e) => {
        const { search } = this.props
        const searchText = e.target.value;
        this.setState({ searchText }, () => { search.searchText && !searchText && this.onSearch(); });
    };

    setContentType = (e) => {
        const { handleChange, onSearch } = this.props;
        let contentType = (e && e.key) || "";
        if (contentType === "/") contentType = "";

        handleChange("contentType")(contentType);
        this.setState({ contentType }, () => onSearch(""));
    };

    render() {
        let { user, handleSearchByModal } = this.props;
        let thumb, name, email, role;
        if (user) {
            name = user.attributes.name;
            email = user.attributes.email;
            thumb = user.attributes.avatar_thumb;
            role = user.relationships.role.data.name;
        }

        const path = this.getmyDashboardPath();
        const tk = localStorage.getItem("jwt");
        const dashboardUrl = `${ENV}/authenticate?tk=${tk}&url=${path}`;

        return (
            <Layout.Header className="fixedHeader" style={{ height: 90 }}>
                <Row
                    className="pt10 pr10 pl10"
                    type="flex"
                    align="middle"
                    justify="space-between"
                >
                    {/* logo start */}
                    <Col xs={0} sm={0} md={2} lg={2} xl={2} xxl={2} span={2}>
                        <a href="/">
                            <img
                                src="/assests/images/henkel.png"
                                alt="Henkel"
                                style={{ height: "40px" }}
                            />
                        </a>
                    </Col>
                    {/* logo end */}

                    <Col
                        xs={24}
                        sm={24}
                        md={18}
                        lg={18}
                        xl={18}
                        xxl={18}
                        span={18}
                    >
                        <Row
                            className="fw"
                            style={{ margin: "none" }}
                            type="flex"
                            align="middle"
                            justify="center"
                        >
                            <Col xs={0} sm={0} md={0} lg={1} xl={3} xxl={6} />
                            <Col xs={10} sm={10} md={8} lg={6} xl={6} xxl={6} className="mr15">
                                <Input
                                    className="fw"
                                    prefix={<Icon type="search" />}
                                    suffix={
                                        <Icon
                                            type="control"
                                            onClick={handleSearchByModal}
                                        />
                                    }
                                    placeholder="Search..."
                                    defaultValue={this.state.searchText}
                                    onChange={this.handleChange}
                                    onPressEnter={this.onSearch}
                                    allowClear={true}
                                    size="large"
                                />
                            </Col>

                            <Col xs={13} sm={13} md={16} lg={15} xl={13} xxl={10}>
                                <Menu
                                    mode="horizontal"
                                    style={{ borderBottom: "none" }}
                                    defaultSelectedKeys="/"
                                    onSelect={this.setContentType}
                                >
                                    <Menu.Item className="pl15 pr15" key="/">
                                        Home
                                    </Menu.Item>
                                    <Menu.Item
                                        className="pl15 pr15"
                                        key="Infographic"
                                    >
                                        Infographic
                                    </Menu.Item>
                                    <Menu.Item
                                        className="pl15 pr15"
                                        key="3D Model"
                                    >
                                        3D Model
                                    </Menu.Item>
                                    <Menu.Item
                                        className="pl15 pr15"
                                        key="Pdf"
                                    >
                                        PDFs
                                    </Menu.Item>
                                    <Menu.Item
                                        className="pl15 pr15"
                                        key="Video"
                                    >
                                        Videos
                                    </Menu.Item>
                                    <Menu.Item className="pl15 pr15" key="Lab">
                                        Lab
                                    </Menu.Item>
                                </Menu>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={0} sm={0} md={4} lg={4} xl={4} xxl={4} span={4}>
                        <LoggedUserDetails
                            isSearching={true}
                            name={name}
                            email={email}
                            role={role}
                            thumb={thumb}
                            logout={this.props.logout}
                            dashboardUrl={dashboardUrl}
                            showSearchEngineLink
                        />
                    </Col>
                </Row>
            </Layout.Header>
        );
    }
}
