import React from 'react';
import { Input, Radio, Row, Col, Icon } from 'antd';
import common_scripts from '../common_functions/script';

export default class SearchDirectory extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            searchText : props.search.searchText
        }
    }

    writing = (e) => {
        this.setState({searchText: e.target.value});
        if(e.target.value.trim() === ""){
            this.props.changeSearchText(e.target.value);
        }
    }

    search = (e) => {
        if(this.props.userEmail){
            let validEmail = common_scripts.validateEmail(this.props.userEmail);
            if(validEmail){
                let category = common_scripts.getFilterCategory(this.props.search.searchFilter);
                return this.props.onSearch(e, category);
            }
            return this.props.showMessageModal('error','Error', 'Please Enter a valid email-id');
        }
        this.props.showMessageModal('error','Error', 'Please enter an email to start searching');
    }

    changeFilter = (e) => {
        this.props.changeFilter(e.target.value, this.props.searchParams);
    }


    render(){
        let {shadowClass, searchFilterClass, search} = this.props;
        let gifColSize = 4;
        if(!search.isSearching){
            gifColSize = 6;
        }
        return (
            <Row gutter={{xs: 24, sm: 24, md: 8, lg: 8}} type="flex" justify="center" className={searchFilterClass}>
                <Input.Search className     =   {shadowClass}
                              placeholder   =   "Search"
                              defaultValue  =   {this.state.searchText}
                              onChange      =   {e=>this.writing(e)}
                              onSearch      =   {e => this.search(e)}
                              enterButton   =   {true}
                              allowClear    =   {true}
                              size          =   "large"
                />
                <Radio.Group onChange={e=>this.changeFilter(e)} value={search.searchFilter} style={{lineHeight: "2.5"}}>
                    {/* <Col xs={6} sm={6} md={3} lg={3} xl={search.isSearching ? 2 : 4} span={4}> */}
                        <Radio value='All'>
                            <Icon type='folder-open' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />All
                        </Radio>
                    {/* </Col> */}
                    {/* <Col xs={10} sm={10} md={6} lg={6} xl={search.isSearching ? 4 : 6} span={6}> */}
                        <Radio value='Source File'>
                            <Icon type='file' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />Source File
                        </Radio>
                    {/* </Col> */}
                    {/* <Col xs={0} sm={0} md={4} lg={4} xl={search.isSearching ? 4 : 4} span={4}> */}
                        <Radio value='Video'>
                            <img alt='' src='assests/images/video_1.png' style={{width:'25px',marginRight:'5px',verticalAlign:'-2px'}} />Video
                        </Radio>
                    {/* </Col> */}
                    {/* <Col xs={0} sm={0} md={search.isSearching ? 4 : gifColSize} lg={search.isSearching ? 4 : gifColSize} xl={search.isSearching ? 4 : gifColSize} span={search.isSearching ? 4 : gifColSize}> */}
                        {
                            (!search.isSearching && search.searchFilter!=='All' && search.searchFilter!=='Source File' && search.searchFilter!=='Video'&& search.searchFilter!=='Gif' ) 
                            ? 
                                <Radio value={search.searchFilter}>
                                    {search.searchFilter === 'Feedback'
                                    ?
                                        <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'10px'}} />
                                    :
                                        <img alt='' src={'assests/images/'+search.searchFilter+'.png'} style={{width:'22px',marginRight:'5px',verticalAlign:'-5px'}} />}
                                        {search.searchFilter}
                                </Radio> 
                            : 
                                <Radio value='Gif'>
                                    <img alt='' src='assests/images/gif_1.png' style={{width:'22px',marginRight:'5px',verticalAlign:'-5px'}} />Gif
                                </Radio>
                        }
                    {/* </Col> */}
                    {
                        search.isSearching &&
                        // <Col xs={0} sm={0} md={0} lg={0} xl={4} span={5}>
                            <Radio value='Infographic'>
                                <img alt='' src='assests/images/infographic.png' style={{width:'25px',marginRight:'5px',verticalAlign:'-6px'}} />Infographic
                            </Radio>
                        // </Col>
                    }
                    {
                        search.isSearching &&
                        // <Col xs={0} sm={0} md={0} lg={0} xl={4} span={5}>
                            <Radio value='Image Sets'>
                                <img alt='' src='assests/images/image sets.png' style={{width:'25px',marginRight:'5px',verticalAlign:'-3px'}} />Image Sets</Radio>
                        // </Col>
                    }
                    {
                        search.isSearching &&
                        (
                            ( search.searchFilter !== 'All' && search.searchFilter !=='Source File' && search.searchFilter !=='Video' 
                              && search.searchFilter !== 'Gif' && search.searchFilter !== 'Infographic' && search.searchFilter !== 'Image Sets'
                              && search.searchFilter !== 'Presentation') ?
                            // <Col xs={0} sm={0} md={0} lg={0} xl={4} span={5}>
                                <Radio value={search.searchFilter}>
                                    {search.searchFilter === 'Feedback'
                                    ?
                                        <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'10px'}} />
                                    :
                                        <img alt='' src={'assests/images/'+search.searchFilter+'.png'} style={{width:'22px',marginRight:'5px',verticalAlign:'-5px'}} />}
                                        {search.searchFilter}
                                </Radio>
                            // </Col>
                            :
                            // <Col xs={0} sm={0} md={0} lg={0} xl={4} span={5}>
                                <Radio value='Presentation'>
                                    <img alt='' src='assests/images/presentation.png' style={{width:'25px',marginRight:'5px',verticalAlign:'-4px'}} />Presentation
                                </Radio>
                            // </Col>
                        )
                    }
                    {/* <Col xs={2} sm={2} md={2} lg={2} xl={2} span={2}> */}
                        <Radio value='More'>
                            <Icon type='export' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />More
                        </Radio>
                    {/* </Col> */}
                </Radio.Group>
            </Row>
        )
    }
}