import React from 'react';
import {Row, Col, Spin} from 'antd';
import SearchDirectory from "./search_directory";

export default class SearchComponent extends React.Component{
    render(){
        let {search, loading, userEmail} = this.props;

        return(
            <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} type="flex" justify="center" className="content-center">
                <Col xs={20} sm={20} md={16} lg={10} xl={10} span={10} style={{marginBottom: "10%"}}>
                    <Spin spinning={loading}>
                        <div className="search-header" style={{marginBottom : "5px"}}>
                            <span className="search-header-text">Welcome to</span>
                            <span className="search-header-org-text"> HENKEL</span>
                        </div>
                        <SearchDirectory onSearch            =   {this.props.onSearch}
                                         search              =   {search}
                                         userEmail           =   {userEmail}
                                         shadowClass         =   "header-search-shadow"
                                         searchFilterClass   =   "search-filter"
                                         changeFilter        =   {this.props.changeFilter}
                                         setSearching        =   {this.props.setSearching}
                                         changeSearchText    =   {this.props.changeSearchText}
                                         showMessageModal    =   {this.props.showMessageModal}
                        />
                    </Spin>
                </Col>
            </Row>
        )
    }
}