import React from 'react';
import {Button, Avatar, Spin} from 'antd';

export default class ErrorFileDownload extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading : false
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(this.props.retry !== nextProps.retry){
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps){
        if(this.props.retry !== prevProps.retry){
            this.setState({loading : !this.props.retry});
        }
    }

    startLoader = (file) => {
        this.setState({loading : true});
        this.props.getFilePreview(file);
    }

    render(){
        let {file, retry} = this.props;
        return(
            <Spin spinning={this.state.loading}>
                <div className="no-preview">
                    {
                        retry &&
                            <Avatar
                                title       =   "Click to reload file preview"
                                icon        =   "reload"
                                size        =   "small"
                                style       =   {{float : "right", cursor:"pointer"}}
                                onClick     =   {e=>this.startLoader(file)}
                            />
                    }
                    <h1 style={{paddingTop : "70px", color : "#656060"}}>
                        {this.props.retry ? "Failed to load preview" : "No Preview Found"}
                    </h1>
                    <Button
                        style       =   {{marginTop : "30%"}}
                        loading     =   {this.props.loaderPercentage>0}
                        onClick     =   {e => this.props.download(e, file.id, file.attributes.filename)}
                    >
                        {this.props.loaderPercentage > 0 ? "Downloading file" : "Click to download file"}
                    </Button>
                </div>
            </Spin>
        )
    }
}