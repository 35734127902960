import React from 'react';
import {Icon} from 'antd';

export default class ImageContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // transform : {transform: "translate3d(0px, 0px, 0px) rotate(0deg) scale(1, 1)"}
            scale : 1
        }
    }

    componentDidMount(){

    }

    drag = (e) => {
        e.dataTransfer.setData("text", e.target.id);
    }

    changeWidth = (e, type) => {
        console.log(type);
        // let _id = this.props.file.attributes.filename+'_'+this.props.file.id;
        // let myImg = document.getElementById(_id);
        if(type === "in"){
            if(this.state.scale >= 5) return false;
            else{
                this.setState({scale : this.state.scale + 0.25});
            }
        }else if(type ==="out"){
            if(this.state.scale <= 0.25) return false;
            else{
                this.setState({scale : this.state.scale - 0.25});
            }
        }

        // let currWidth = myImg.clientWidth;
        // if (currWidth == 2500) return false;
        // else {
        //     myImg.style.transform = (currWidth + 100) + "px";
        // }
    }

    render(){
        let {file} = this.props;
        return (
            <div className="preview_image_container" id={`${file.id}p_img_container`}>
                <img alt={file.attributes.filename}
                     src={file.blobURL}
                     draggable
                     onDragStart={e=>this.drag(e)}
                     id={file.attributes.filename+'_'+file.id}
                     style={{transform : `translate3d(0px, 0px, 0px) rotate(0deg) scale(${this.state.scale})`}} />
                <div className="p_image_slider" style={{display : "none"}}>
                    <div className="p_img_slider_container">
                        <div className="p_img_slider_group">
                            <Icon type="minus" className="p_img_icon_left" onClick={e=>this.changeWidth(e, 'out')} />
                            <Icon type="plus" className="p_img_icon_right" onClick={e=>this.changeWidth(e, 'in')} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}