import React from 'react';

export default class Redirect extends React.Component{

    componentDidMount(){
        let _this = this;
        setTimeout(function(){
            _this.validateToken(_this.props.location);
        }, 2000);
    }

    validateToken = (location) => {
        if(location && location.search){
            let search = location.search;
            let token = search.substr(4);
            localStorage.setItem('jwt', token);
            this.props.history.push(this.props.loc);
            // window.location = this.props.loc;
        }else{
            this.props.history.push(this.props.loc);
            // window.location = this.props.loc;
        }
    }

    render(){
        return (
            <div className="center transition-6">
                <div className="f-md">Please wait while we redirect you to the <b>Content Repository</b></div>
                <div>
                    <span>If you are not redirected within 2 seconds then <a href={this.props.loc || "/search"}> click here </a> to Go Content Repository</span>
                </div>
            </div>
        )
    }
}