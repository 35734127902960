import React from 'react';

export default class VideoContainer extends React.Component {

    componentDidUpdate(nxtProps, nxtState){
        if(nxtProps.file.id !== nxtProps.selectedFile.id){
            let videoDocument = document.getElementById(`video_${nxtProps.file.id}`);
            videoDocument.pause();
        }
    }

    render() {
        let {file} = this.props;
        let element;
        switch(file.attributes.extension.toLowerCase()){
            case 'mov' :
            case 'mp4' :
            case 'webm':
            case 'm4v' :
                element = <video id={`video_${file.id}`} autoPlay width="100%" height="80%" controls autobuffer="true">
                                <source src={file.blobURL} type="video/mp4"></source>
                                Your browser doesn't support HTML5 video in WebM with VP8/VP9 or MP4 with H.264.
                          </video>;
                break;
            case 'flv' :
            case 'avi' :
            case 'mpg' :
            case 'mpeg':
                element = <embed src={file.blobURL} type="video/x-msvideo"
                                 width="320" height="250" controller="true" loop={false}
                                 autoPlay name={file.attributes.fileName}></embed>
                // element = <object width="360" height="250" ext={extension}>
                //                 <param name="src" value={url} />
                //                 <embed src={url}></embed>
                //           </object>;
                break;
            default : element = <div className="error">Invalid file format provided for Audio/Video</div>;
        }

        return (
            <div>
                {element}
            </div>
        )
    }
}