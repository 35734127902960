import React from 'react';
import {Row, Col, Card, Spin, Tooltip, notification,Empty,Tag, Icon, Button, Select } from 'antd';
import LoadMore from "./load_more";
import common_scripts from "../common_functions/script";
import {FileIntiateRequestModal} from "./file_card_modal";
import axios from "axios/index";
import moment from 'moment';
import api_url from "../common_functions/api_constants";
import FilePreviewContainer from "../file_preview/file_preview_container";

let emptyStyle = {
    display: "flex",
    justifyContent: "center",
    height: "60vh",
    alignItems: "center"
};
let cardHeadStyle = {
    padding: 0,
    background: "inherit!important",
    minHeight: 0,
    height: 0
};
let cardBodyStyle = {
    // backgroundColor: "rgb(254, 245, 241)",
    // border: "1px solid rgb(234, 216, 207)", 
    padding:"10px"
};

export default class CardDetails extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showLoader          : false,
            showDrawer          : false,
            drawerDetails       : null,
            loaderPercentage    : 0,
            activeFile          : null,
            // fileTaskDetails     : null
        }
        this.selectedIndexFile = 0;//initial index of file -- will change when we select a file
    }

    componentDidMount(){
        if(this.props.search.isSearching){
            let body = document.getElementsByTagName('body');
            body[0].className = "";
        }
    }

    changeDrawerDetails = (val) => {
        this.setState({drawerDetails : val});
    }

    showDrawer = (val) => {
        this.setState({showDrawer : val});
        if(!val){
            this.changeDrawerDetails(null);
        }
    }

    changeCardLoader = (val) => {
        this.setState({showLoader : val});
    }

    setSelectedCardDetails = (file) => {
        //set drawer details
        this.showDrawer(true);
        this.changeDrawerDetails(file);
        // this.getFileDetailsFromTask(file);
    }

    loadMoreData = () => {
        this.changeCardLoader(true);
        //make API call to get Data
        let category = common_scripts.getFilterCategory(this.props.search.searchFilter);
        let filters = `type=file&text=${this.props.search.searchText}&category=${category}`;
        common_scripts.loadMoreSearchedData(undefined, filters, '100', this.props.searchedData.meta.count).then(res=>{
            console.log(res.data);
            this.changeCardLoader(false);
            if(res.data){
                this.props.mergeSearchData(res.data.data, res.data.meta);
            }
        }).catch(err=>{
            console.log(err);
            this.changeCardLoader(false);
        })
    }

    showNotification = (type, title, description, btn) => {
        notification[type]({
            message: title,
            description: description,
            btn
        });
    }

    download = (e, id, filename) => {
        let _this = this;

        //API to increment download counter
        common_scripts.updateFileParams(`ids=${id}&download=true`).then(rfd=>{
            console.log('success updating file download counter', rfd);
        }).catch(err=>{
            console.log('Error incrementing file download counter', err);
        })

        axios({
            method: 'GET',
            url: api_url.TASK_URL + '/get-attachment/' + id,
            contentType: 'application/doc; charset=utf-8',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt')},
            responseType: 'arraybuffer',
            onDownloadProgress: function (progressEvent) {
                let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                _this.setState({loaderPercentage : percent});
            }
        }).then(response => {
            let fileDownload = require('js-file-download');
            fileDownload(response.data, filename);
            _this.setState({loaderPercentage : 0});
        }).catch(err=>{
            console.log('Error downloading file',err);
            _this.showNotification('error', 'Unable to Download File', err.message);
            _this.setState({loaderPercentage : 0});
        })
    }

    setActiveFile = (e, file, index) => {
        if(e){
            e.stopPropagation();
        }
        this.setState({activeFile : file});
        // this.getFileDetailsFromTask(file);
        this.selectedIndexFile = index || 0;
    }

    changeFilter = (filterType) => {
        let str = this.props.search.searchParams;
        let filterParams = this.props.search.searchParams;
        if(filterType === "downloaded"){
            let matchFound = str.search('&sort=download');
            if(matchFound === -1){
                filterParams = '&sort=download&order=desc';
            }
        }else if(filterType === "viewed"){
            let matchFound = str.search('&sort=view');
            if(matchFound === -1){
                filterParams = '&sort=view&order=desc';
            }
        }else if(filterType === "requested"){
            let matchFound = str.search('&sort=request');
            if(matchFound === -1){
                filterParams = '&sort=request&order=desc';
            }
        }
        this.props.changeFilter(this.props.search.searchFilter, filterParams);
    }

    handleSortFilter = (e) => {
        console.log(e);
        let sortFilterParams = "";
        if(e === "today"){
            sortFilterParams = `&startDate=${moment().startOf('day').utc().format()}&endDate=${moment().endOf('day').utc().format()}`;
        }else if(e === "last_week"){
            sortFilterParams = `&startDate=${moment().subtract(1, 'week').startOf('week').utc().format()}&endDate=${moment().subtract(1, 'week').endOf('week').utc().format()}`
        }else if(e === "last_month"){
            sortFilterParams = `&startDate=${moment().subtract(1, 'month').startOf('month').utc().format()}&endDate=${moment().subtract(1, 'month').endOf('month').utc().format()}`;
        }else if(e === "last_year"){
            sortFilterParams = `&startDate=${moment().subtract(1, 'year').startOf('year').utc().format()}&endDate=${moment().subtract(1, 'year').endOf('year').utc().format()}`
        }
        this.props.changeFilter(this.props.search.searchFilter, this.props.search.searchParams, sortFilterParams);
    }

    // getFileDetailsFromTask = (file) =>{
    //     if(file){
    //         common_scripts.getFileDetailsFromTask(file.id).then(res=>{
    //             console.log(res);
    //             this.setState({fileTaskDetails : res.data.data});
    //         }).catch(err=>{
    //             console.log('Error getting details of file from tasks ', err);
    //         })
    //     }
    // }

    render(){
        let showLoaderBtn = false, files, totalCount = 0, count = 0;//, dataExist = false;
        if(!Array.isArray(this.props.searchedData) && this.props.searchedData.data.length > 0){
            showLoaderBtn   = this.props.searchedData.meta.totalCount <= this.props.searchedData.data.length ? false : true;
            totalCount      = this.props.searchedData.meta.totalCount;
            count           = this.props.searchedData.meta.count;
            // dataExist       = true;
            files = this.props.searchedData.data.map((file, index)=>{
                return <SingleFileDetail 
                            key                     =   {file.id}
                            file                    =   {file}
                            index                   =   {index}
                            drawerDetails           =   {this.state.drawerDetails}
                            setActiveFile           =   {this.setActiveFile}
                            setSelectedCardDetails  =   {this.setSelectedCardDetails}
                        />
            });
        }else{
            files = <Empty
                        image="/assests/images/not_found.png"
                        style={emptyStyle}
                        description={<span style={{position: "relative",top: "85px", right: "136px"}}></span>}
                    />
        }

        return(
            <React.Fragment>
                <FilterButtons 
                    changeFilter        =   {this.changeFilter} 
                    handleSortFilter    =   {this.handleSortFilter}
                    searchParams        =   {this.props.search.searchParams}
                />
                <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}}>
                    <div className=" pull-left f-lg">
                        Showing results for <span className="org-text">"{this.props.search.searchText}"</span>
                    </div>
                    <div className="pull-right">
                        Showing <span className="org-text">{count}</span> of <span className="org-text">{totalCount}</span>
                    </div>
                </Row>
                <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} style={{marginTop : "5px"}}>
                    <Col span={24}>
                        <Spin spinning={this.state.showLoader || this.props.loading} size="large">
                            <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} style={{marginLeft : 'auto', width : '95%'}}>
                                {files}
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={24}>
                        <LoadMore
                            name                =   "Files"
                            showLoaderButton    =   {showLoaderBtn}
                            showLoader          =   {this.state.showLoader}
                            loadMoreData        =   {this.loadMoreData}
                        />
                    </Col>
                </Row>
                {
                    this.state.activeFile &&
                        <FilePreviewContainer
                            files               =   {this.props.searchedData.data}
                            activeFile          =   {this.state.activeFile}
                            setActiveFile       =   {this.setActiveFile}
                            type                =   "tasks"
                            url                 =   "TASK_URL"
                            selectedIndexFile   =   {this.selectedIndexFile}
                            showNotification    =   {this.showNotification}
                            // fileTaskDetails     =   {this.state.fileTaskDetails}
                        />
                }
                {
                    this.state.drawerDetails &&
                        <FileIntiateRequestModal
                            showDrawer          =   {this.state.showDrawer}
                            loaderPercentage    =   {this.state.loaderPercentage}
                            drawerDetails       =   {this.state.drawerDetails}
                            user_details        =   {this.props.user_details}
                            user_notes          =   {this.props.user_notes}
                            closeDrawer         =   {this.showDrawer}
                            download            =   {this.download}
                            showNotification    =   {this.showNotification}
                            changeDrawerDetails =   {this.changeDrawerDetails}
                            // fileTaskDetails     =   {this.state.fileTaskDetails}
                        />
                }
            </React.Fragment>
        )
    }
}

export const getFileThumbImg = (file) => {
    let img_src, file_style = {};
    let thumb_location = file.attributes ? file.attributes.thumb_location : file.thumb_location;
    let file_extension = file.attributes ? file.attributes.extension : file.extension;
    let filename = file.attributes ? file.attributes.filename : file.filename;
    if(thumb_location){
        img_src = thumb_location;
        file_style = {height: "150px"};
        return <img src={img_src} alt={filename} style={file_style} />;
    }
    //if thumb is not present
    img_src = '/assests/images';
    file_style = {width : "70px"};
    switch(file_extension.toLowerCase()){
        case "pdf" : img_src = `${img_src}/pdf.png`;
            break;
        case "rar" : img_src = `${img_src}/rar.png`;
            break;
        case "zip" : img_src = `${img_src}/zip.png`;
            break;
        case "ppt" :
        case "pptx": img_src = `${img_src}/ppt.png`;
            break;
        case "xls" :
        case "xlsx": img_src = `${img_src}/xls.png`;
            break;
        case "csv" : img_src = `${img_src}/csv.png`;
            break;
        case "doc" :
        case "docx":
        case "txt" : img_src = `${img_src}/doc.png`;
            break;
        case "aac" :
        case "mp3" :
        case "m4a" :
        case "ogg" :
        case "wav" :
        case "webm":
        case "wma" : img_src = `${img_src}/audio.png`;
            break;
        case "dwg" :
        case "dwl" : img_src = `${img_src}/dwg.png`;
            break;
        case "png" : img_src = `${img_src}/png.png`;
            break;
        case "jpg" :
        case "jpeg": img_src = `${img_src}/jpg.png`;
            break;
        case "gif" : img_src = `${img_src}/gif.png`;
            break;
        case "avi" :
        case "flv" :
        case "mov" :
        case "mp4" :
        case "m4v" :
        case "mpg" :
        case "mpeg": img_src = `${img_src}/video.png`;
            break;
        default: img_src = `${img_src}/doc.png`;
            break;
    }
    return <div className="search-image"><img src={img_src} alt={filename} style={file_style} /></div>
}

const FilterButtons = (props)=> {
    return <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} style={{marginTop : "10px", marginBottom :  "10px"}}>
                <Button 
                    shape="round" 
                    icon="download" 
                    size="large" 
                    style={{marginRight : "10px", color : "red", border : `${props.searchParams.match('download') ? '1px solid red' : ''}`}}
                    onClick={e=>props.changeFilter('downloaded')}
                >
                    <span style={{color : "#676262"}}>Most Downloaded</span>
                </Button>
                <Button 
                    shape="round" 
                    icon="eye" 
                    size="large" 
                    style={{marginRight : "10px", color : "red",border : `${props.searchParams.match('view') ? '1px solid red' : ''}`}}
                    onClick={e=>props.changeFilter('viewed')}
                >
                    <span style={{color : "#676262"}}>Most Viewed</span>
                </Button>
                <Button 
                    shape="round" 
                    icon="rocket" 
                    size="large" 
                    style={{marginRight : "10px", color : "red",border : `${props.searchParams.match('request') ? '1px solid red' : ''}`}}
                    onClick={e=>props.changeFilter('requested')}
                >
                    <span className="" style={{color : "#676262"}}>Most Requested</span>
                </Button>
                <Select className="pull-right" defaultValue='sort' size="large" style={{ width: 150 }} onChange={e=>props.handleSortFilter(e)}>
                    <Select.Option value="sort">Filter</Select.Option>
                    <Select.Option value="today">Today</Select.Option>
                    <Select.Option value="last_week">Last Week</Select.Option>
                    <Select.Option value="last_month">Last Month</Select.Option>
                    <Select.Option value="last_year">Last Year</Select.Option>
                </Select>
            </Row>
}

const SingleFileDetail = (props) => {
    let {file, index, drawerDetails} = props;
    let file_cover = getFileThumbImg(file);
    let extra;
    let iconStyle = {
        fontSize:'18px'
        // padding:"5px",
        // transform: "translatez(0)",
        // background: "#ccc",
        // borderRadius: "6px", 
        // marginRight : "5px"
    }
    // console.log('file',file)
    let category = file.attributes.category && file.attributes.category.split('_');
    category     = category && category.join(' ');
    category     = category === 'video' || category === 'gif' ? category+'_1' : category;
    // if(file.attributes.category === "source_file"){
    //     extra = <span style={{position:'relative',bottom:'10px'}}>
    //         <Tag style={{transform: "translatez(0)"}} color="volcano">Source File</Tag>
    //         <Icon 
    //             type="eye"
    //             title='Click to Preview File'
    //             className = "pull-right"
    //             style={iconStyle}
    //             onClick={(e)=>props.setActiveFile(e, file, index)}
    //         />
    //     </span>;
    // }else{
        extra = <span style={{position:'absolute',top:'0',right:'0',borderRadius:'4px',padding:'5px',background:'white',display:'flex',alignItems:'center'}}>
                    {
                        category === 'all' || category === 'source file' || category === 'feedback file'
                        ?
                            <span>
                                {/* {
                                    category === 'all' &&
                                        <Icon type='folder-open' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />
                                } */}
                                {
                                    category === 'source file' &&
                                        <Tag style={{transform: "translatez(0)"}} color="rgb(226, 0, 21)">Source File</Tag>
                                        // <Icon type='file' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />
                                }
                                {
                                    category === 'feedback file' &&
                                        <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />
                                }
                            </span>
                        :
                            <img src={'assests/images/'+category+'.png'} alt={category} style={{width:category === 'emailer' ? '20px' :'25px',marginRight:'5px'}}/>
                    }
                    <Icon type="eye"
                        title='Click to Preview File'
                        style={{...iconStyle}}
                        onClick={(e)=>props.setActiveFile(e, file, index)}
                    />
                </span>
    // }

    let file_name = file.attributes ? file.attributes.filename : file.filename;
    let file_ext  = file_name.substring(file_name.lastIndexOf('.')+1);
    if (file_name.length > 25){
        file_name = file_name.substring(0,20)+'...'+file_ext;
    } 


    let fileTitle = <span>
        {file.attributes.filename}
        <small style={{display : 'block'}}>Last Modified At : {moment(file.attributes.modified_at).format('LL')}</small>
    </span>

    return <Col xs={24} sm={12} md={6} lg={4} xl={4} span={4} style={{margin:"15px"}}>
        <Card
            onClick     =   {e=>props.setSelectedCardDetails(file)}
            hoverable   =   {true}
            bordered    =   {true}
            headStyle   =   {cardHeadStyle}
            extra       =   {extra}
            style       =   {(drawerDetails && file.id === drawerDetails.id) ? {border: "1px solid rgba(6, 187, 241, 0.87)"} : {}}
            bodyStyle   =   {cardBodyStyle}
            cover       =   {file.attributes && file.attributes.thumb_location ? file_cover : null}
            actions     =   {
                [
                    <span style={{color : '#f5222d'}} title={`${file.attributes.download} Downloads`}><Icon type='download' /> {file.attributes.download}</span>,
                    <span style={{color : '#f5222d'}} title={`${file.attributes.view} Views`}><Icon type='eye' /> {file.attributes.view}</span>,
                    <span style={{color : '#f5222d'}} title={`${file.attributes.request} Requests`}><Icon type='rocket' /> {file.attributes.request}</span>
                ]
            }
        >
            {!(file.attributes && file.attributes.thumb_location) ? file_cover : null}
            <Tooltip placement="bottom" title={fileTitle}>
                <Card.Meta
                    title       =   {file_name}
                    description =   {file.attributes ? file.attributes.description : file.description }
                />
            </Tooltip>
        </Card>
    </Col>
}