import React from 'react';
import {Modal, Carousel, Avatar, Icon, Tag} from 'antd';
import {FileCarousel} from "./file_carousel";
import common_scripts from "../common_functions/script";
import api_url from "../common_functions/api_constants";
import {TextFileContainer} from "./text_file_container";

const imgSet            = new Set(['jpg', 'jpeg', 'png','gif']);
const docSet            = new Set(['doc', 'docx','xls','xlsx','ppt','pptx','csv']);
const textSet           = new Set(['txt']);
const pdfSet            = new Set(['pdf']);
const compressedSet     = new Set(['zip','rar']);
const audioSet          = new Set(['aac', 'mp3', 'm4a', 'ogg', 'wav', 'wma']);
const videosSet         = new Set(['webm','flv', 'avi', 'mov', 'mp4', 'm4v', 'mpg', 'mpeg']);

export default class FilePreviewContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            files           : props.files,
            activeFile      : props.activeFile,
            fileTaskDetails : props.fileTaskDetails
        }
        this.currentFileIndex = this.props.selectedIndexFile;
        this.currentFileName  = this.props.activeFile ? this.props.activeFile.attributes.filename : "";
    }

    componentDidMount(){
        //add blob url in all the files data
        this.createNewFileData();
    }

    componentWillReceiveProps(nxtProps){
        this.setState({fileTaskDetails:nxtProps.fileTaskDetails})
    }

    createNewFileData = () => {
        let files = this.props.files.map(file=>{
            return {
                ...file,
                blobURL : null,
                active  : this.props.activeFile.id === file.id,
                element : null,
                error   : false
            };
        });
        this.setState({files});
    }

    changeFileData = (file) => {
        let files = this.state.files.map(f =>{
            if(file.id === f.id){
                f.element   = file.element;
                f.blobURL   = file.blobURL;
                f.error     = file.error;
                this.currentFileName = file.attributes.filename;
            }
            return f;
        });
        this.setState({files});
    }

    afterChange = (index) => {
        this.checkIfBlobURLExists(index);
        this.currentFileIndex = index;
        let file = this.state.files.find((f, i) => index === i );
        if(file){
            this.changeActiveFileCarausel(file);
        }
    }

    checkIfBlobURLExists = (index) => {
        let currentFile = this.props.files.find((file, i)=>index === i);
        if(currentFile){
            if(!currentFile.blobURL){
                this.getFilePreview(currentFile);
            }
        }
    }

    getFilePreview = (file) => {
        let path = "get-attachment";
        if(docSet.has(file.attributes.extension)){
            path = "get-preview";
        }
        let api = `${api_url[this.props.url]}/${path}/${file.id}`;
        
        //API to increment view counter
        common_scripts.updateFileParams(`ids=${file.id}&view=true`).then(rfd=>{
            console.log('success updating file view counter', rfd);
        }).catch(err=>{
            console.log('Error incrementing file view counter', err);
        })
        // if(file.attributes.extension.toLowerCase() === 'zip' || file.attributes.extension.toLowerCase() === 'rar'){
        //     file.blobURL = 'zip';
        //     file.error = false;
        //     return this.changeFileData(file);
        // }

        common_scripts.getFilePreview(api, undefined).then(resd=>{
            let {blobURL} = this.getElement(resd.data, file);
            if(file.attributes.extension !== "txt"){
                // file.element = element;
                file.blobURL = blobURL;
                file.error   = false;
                file.data    = resd.data;
                this.changeFileData(file);
            }
        }).catch(err=>{
            console.log('error', err);
            file.error = true;
            this.changeFileData(file);
        });
    }

    getElement = (response, file) => {
        let blobURL, extension = file.attributes.extension.toLowerCase();
        let url = window.URL || window.webkitURL;

        switch(true){
            case imgSet.has(extension):
                blobURL = url.createObjectURL(new Blob([response]));
                break;
            case textSet.has(extension):
                let blob    = new Blob([response], {type:'text/plain'});
                let reader  = new FileReader();
                reader.addEventListener('loadend',(e)=>{
                        blobURL = url.createObjectURL(blob);
                        let element = <TextFileContainer fileResult={e} />;
                        file.element = element;
                        file.blobURL = blobURL;
                        this.changeFileData(file);
                    });
                // reader.readAsText(blob, 'UTF-8');
                reader.readAsBinaryString(blob);
                break;
            case pdfSet.has(extension):
            case docSet.has(extension):
                blobURL = url.createObjectURL(new Blob([response], {type:'application/pdf'}));
                break;
            case compressedSet.has(extension):
                blobURL = url.createObjectURL(new Blob([response], {type:'application/zip'}));
                break;
            case audioSet.has(extension):
                blobURL = url.createObjectURL(new Blob([response], {type:`audio/${extension}`}));
                break;
            case videosSet.has(extension):
                blobURL = url.createObjectURL(new Blob([response], {type:`video/${extension}`}));
                break;

            default: blobURL = null;
        }

        return {blobURL};
    }

    changeActiveFileCarausel = (file) => {
        this.setState({activeFile : file});
    }

    render(){
        console.log('active file', this.state.activeFile)
        let category = this.state.activeFile.attributes.category && this.state.activeFile.attributes.category.split('_');
        category     = category.join(' ');
        category     = category === 'video' || category === 'gif' ? category+'_1' : category;
        let caraouselSetting = {
            afterChange         :   this.afterChange,
            // beforeChange        :   this.beforeChange,
            style               :   {width : "100%", height: "100%", background: "#364d79", overflow : "hidden"},
            dots                :   this.state.files.length < 16,
            arrows              :   true,
            infinite            :   false,
            draggable           :   false,
            speed               :   500,
            slidesToShow        :   1,
            slidesToScroll      :   1,
            focusOnSelect       :   true,
            initialSlide        :   this.props.selectedIndexFile,
            nextArrow           :   <SampleNextArrow />,
            prevArrow           :   <SamplePrevArrow />
        }

        return(
            <Modal
                onCancel            =   {(e)=>this.props.setActiveFile(e,null)}
                afterClose          =   {(e)=>this.props.setActiveFile(e, null)}
                centered            =   {true}
                footer              =   {null}
                width               =   "70%"
                // bodyStyle           =   {{height : "85vh",overflowY:"auto",overflowX:"hidden"}}
                bodyStyle           =   {{padding : "10px"}}
                visible             =   {this.props.activeFile !== null}
                // title               =   {this.currentFileName}
                title               =   {
                                        <span>
                                            <span>{this.currentFileName}</span>
                                            <span style={{float:'right',marginRight:'40px'}}>
                                                {
                                                    category === 'all' || category === 'source file' || category === 'feedback file'
                                                    ?
                                                        <span>
                                                            {
                                                                category === 'source file' &&
                                                                    <Tag style={{transform: "translatez(0)"}} color="rgb(226, 0, 21)">Source File</Tag>
                                                            }
                                                            {
                                                                category === 'feedback file' &&
                                                                    <span>
                                                                        <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'5px'}} />
                                                                        <span>Feedback</span>
                                                                    </span>
                                                            }
                                                        </span>
                                                    :
                                                        <span>
                                                            <img src={'assests/images/'+category+'.png'} alt={category} style={{width:'25px',marginRight:'5px'}}/>
                                                            <span style={{textTransform:'capitalize'}}>{this.state.activeFile.attributes.category.split('_').join(' ')}</span>
                                                        </span>
                                                            
                                                }
                                            </span>
                                        </span>
                }
                destroyOnClose      =   {true}
                maskClosable        =   {false}
            >
                <Carousel
                    {...caraouselSetting}
                >
                    {
                        this.state.files.map((file, index)=>{
                            return <FileCarousel
                                        key                 =   {file.id}
                                        selectedFile        =   {this.state.activeFile}
                                        file                =   {file}
                                        imgSet              =   {imgSet}
                                        docSet              =   {docSet}
                                        textSet             =   {textSet}
                                        pdfSet              =   {pdfSet}
                                        audioSet            =   {audioSet}
                                        videosSet           =   {videosSet}
                                        compressedSet       =   {compressedSet}
                                        selectedIndexFile   =   {this.props.selectedIndexFile}
                                        type                =   {this.props.type}
                                        url                 =   {this.props.url}
                                        checkIfBlobURLExists=   {this.checkIfBlobURLExists}
                                        getFilePreview      =   {this.getFilePreview}
                                        showNotification    =   {this.props.showNotification}
                                    />
                        })
                    }
                </Carousel>
            </Modal>
        )
    }
}

const SampleNextArrow = (props) => {
    let { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, position : "sticky", float:"right", zIndex : "1"}}
            style={{ ...style, zIndex : "1", right : "15px"}}
            onClick={onClick}
        ><Avatar shape="square" size="large" icon="right-circle" /></div>
    );
}

const SamplePrevArrow = (props) => {
    let { className, style, onClick } = props;
    return (
        <div
            className={className}
            // style={{ ...style, position : "sticky", zIndex : "1"}}
            style={{ ...style, zIndex : "1", left : "-6px"}}
            onClick={onClick}
        ><Avatar shape="square" size="large" icon="left-circle" /></div>
    );
}