import React, { Component } from "react";
import { Button, Col, Input, Modal, Row, Select, } from "antd";
import { TEAMS } from "../../common_functions/api_constants";
import { fileTypes } from "../../util/common_functions";

class SearchBy extends Component {
    handleChange = (name) => (e) => {
        const { search = {} } = this.props;
        const value = typeof e === "string" ? e : (e && e.target && e.target.value);

        this.props.handleChange(
            Object.assign({}, search, {
                [name]: value,
            })
        );
    };

    onSearch = () => {
        const { handleModal, onSearch } = this.props;
        onSearch();
        handleModal();
    }

    render() {
        const { showModal, handleModal, search = {}, creators = [], onReset } = this.props;
        const {
            title,
            description,
            team,
            creator,
            contentType,
            size,
            sizeCondition = "true",
            sizeUnit = "MB",
            loading = false,
        } = search;

        return (
            <Modal
                title={"Search By"}
                width={620}
                visible={showModal}
                closable={true}
                onCancel={handleModal}
                destroyOnClose={true}
                footer={
                    <>
                        <Button
                            type="primary"
                            onClick={onReset}
                        >
                            Reset
                        </Button>

                        <Button
                            type="primary-outline"
                            loading={loading}
                            onClick={this.onSearch}
                        >
                            Search
                        </Button>
                    </>
                }
            >
                {/* Content Title start */}
                <Row type="flex" align="middle">
                    <Col span={6}>
                        <label className="label">Content Title</label>
                    </Col>

                    <Col span={18}>
                        <Input
                            className="custom-input"
                            placeholder="Input Title"
                            value={title}
                            onChange={this.handleChange("title")}
                            size="large"
                            allowClear
                        />
                    </Col>
                </Row>
                {/* Content Title end */}

                {/* Content Description start */}
                <Row className="mt15">
                    <Col span={6}>
                        <label className="label">Content Description</label>
                    </Col>

                    <Col span={18}>
                        <Input.TextArea
                            className="custom-input"
                            placeholder="Write Description"
                            value={description}
                            onChange={this.handleChange("description")}
                            size="large"
                            allowClear
                        />
                    </Col>
                </Row>
                {/* Content Description end */}

                {/* Team start */}
                <Row type="flex" align="middle" className="mt15">
                    <Col span={6}>
                        <label className="label">Team</label>
                    </Col>

                    <Col span={18}>
                        <Select
                            className="custom-select fw"
                            placeholder="Select Team"
                            size="large"
                            allowClear
                            value={team}
                            onChange={this.handleChange("team")}
                        >
                            {TEAMS.map(({ name }) => (
                                <Select.Option key={name} value={name}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                {/* Team end */}

                {/* Creator/Requestor start */}
                <Row type="flex" align="middle" className="mt15">
                    <Col span={6}>
                        <label className="label">Creator/Requestor</label>
                    </Col>

                    <Col span={18}>
                        <Select
                            className="custom-select fw"
                            placeholder="Select Creator"
                            size="large"
                            allowClear
                            value={creator}
                            onChange={this.handleChange("creator")}
                        >
                            {creators.map(({ attributes, id }) => (
                                <Select.Option key={id} value={id}>
                                    {attributes && attributes.email}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                {/* Creator/Requestor end */}

                {/* Content Type start */}
                <Row type="flex" align="middle" className="mt15">
                    <Col span={6}>
                        <label className="label">Content Type</label>
                    </Col>

                    <Col span={18}>
                        <Select
                            className="custom-select fw"
                            placeholder="Select Content Type"
                            size="large"
                            allowClear
                            value={contentType}
                            onChange={this.handleChange("contentType")}
                        >
                            {Object.values(fileTypes).map(({ name, id }) => (
                                <Select.Option key={id} value={id}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                {/* Content Type end */}

                {/* Size start */}
                <Row type="flex" align="middle" className="mt15">
                    <Col span={6}>
                        <label className="label">Size</label>
                    </Col>

                    <Col span={18}>
                        <Row>
                            <Col span={9}>
                                <Select
                                    className="custom-select fw"
                                    size="large"
                                    value={sizeCondition}
                                    onChange={this.handleChange(
                                        "sizeCondition"
                                    )}
                                >
                                    {[
                                        {
                                            id: "true",
                                            name: "Greater than or equal",
                                        },
                                        {
                                            id: "false",
                                            name: "Less than or equal",
                                        },
                                    ].map(({ name, id }) => (
                                        <Select.Option key={id} value={id}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={7} className="ml10 mr10">
                                <Input
                                    className="custom-input"
                                    placeholder="Input Size"
                                    value={size}
                                    onChange={this.handleChange("size")}
                                    step={1}
                                    size="large"
                                    type="number"
                                />
                            </Col>

                            <Col span={4}>
                                <Select
                                    className="custom-select fw"
                                    placeholder="Select Size"
                                    size="large"
                                    value={sizeUnit}
                                    onChange={this.handleChange("sizeUnit")}
                                >
                                    {["MB", "KB", "Bytes"].map((unit) => (
                                        <Select.Option key={unit} value={unit}>
                                            {unit}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Size end */}
            </Modal>
        );
    }
}

export default SearchBy;
