import React, { Component } from "react";
import { Layout, Modal, message } from "antd";

import Header from "./components/Header";
import SearchByModal from "./components/SearchByModal";
import EmailModal from "../util/EmailModal";

import common_scripts from "../common_functions/script";
import CardDetails from "./components/CardDetails";
import { toBytes } from "../util/common_functions";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResultsString: "",
            showSearchByModal: false,
            contentType: "",
            search: {
                isSearching: false,
                searchText: "",
                sizeCondition: "true",
                sizeUnit: "MB",
                loading: false,
                searchFilter: "All",
                sortFilters: {
                    params: "",
                },
                searchParams: "",
                error: {},
            },
            loading: false,
            user_details: null,
            user_notes: null,
            email: null,
            ms_details: null,
            showModal: false,
            showFilterDrawer: false,
            searchedData: {},
            usersData: {},
        };
    }

    componentDidMount() {
        this.initialSetting();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchedData, usersData } = this.state;

        const oldSearchDataCount = prevState.searchedData.meta && prevState.searchedData.meta.count;
        const newSearchDataCount = searchedData.meta && searchedData.meta.count;
        const maxSearchDataCount = searchedData.meta && searchedData.meta.total_count;
        if (oldSearchDataCount !== newSearchDataCount && newSearchDataCount < maxSearchDataCount) {
            this.startSearching(searchedData.meta.count);
        }

        const oldUsersCount = prevState.usersData.meta && prevState.usersData.meta.count;
        const newUsersCount = usersData.meta && usersData.meta.count;
        const maxUsersCount = usersData.meta && usersData.meta.totalCount;
        if (oldUsersCount !== newUsersCount && newUsersCount < maxUsersCount) {
            this.getAllUsers(usersData.meta.count);
        }
    }

    initialSetting = () => {
        const body = document.getElementsByTagName("body");
        body[0].className = "";

        const token_details = this.getTokenDetails();
        //check if token exists or not
        if (token_details) {
            const validated_token = common_scripts.validateToken();
            if (!validated_token) {
                Modal.error({
                    title: "Error",
                    content:
                        "Your session has expired. Please enter your email to Login into the system.",
                    onOk: () => {
                        this.changeModalVisibility(true);
                        this.changeLoader(false);
                    },
                });
            } else {
                //login with given credentials and token
                this.microsoftLoginValidation({}, validated_token.sub);
            }
        } else {
            //Ask user to enter via microsoft login
            this.changeModalVisibility(true);
        }
    };

    storageListeningEvent = () => {
        console.log("Listening to storage changes");
        // window.addEventListener('storage', function(e) {
        //     console.log('Woohoo, someone changed my localstorage va another tab/window!');
        // });//sample code
        window.addEventListener("storage", this.storageChange);
    };

    microsoftLoginValidation = (ms, email) => {
        this.changeLoader(true);
        //now validate the user -- either create a user or get the user details by verifying the user
        let name =
            ms.displayName && ms.displayName.trim !== ""
                ? ms.displayName
                : email.split("@")[0];
        let data = {
            data: {
                attributes: {
                    email,
                    name,
                    phone: ms.mobilePhone,
                    teams: ["5ahabmnjgoqnlvd"], //staging and live environment
                    role: "5ahagxjjgc35o3z", //staging and live environment
                },
                acc_id: "5ahagxjjgc3wk5z", //staging and live environment
            },
        };
        common_scripts
            .ms_login(data)
            .then((res) => {
                console.log("microsoft login", res);
                if (res.data.success) {
                    this.setState({ ms_details: ms, email });
                    this.changeLoader(false);
                    // localStorage.removeItem('jwt'); //remove token if old data is present
                    localStorage.setItem("jwt", res.data.token); //set new token
                    let validated_token = common_scripts.validateToken(
                        res.data.token
                    );
                    this.getUserDetails(validated_token.id);
                    this.getNotes();
                    this.startSearching();
                    this.getAllUsers();
                    this.changeModalVisibility(false);
                } else {
                    Modal.error({
                        title: "Error",
                        content: res.data.message,
                        onOk: () => {
                            this.changeModalVisibility(true);
                            this.changeLoader(false);
                        },
                    });
                }
            })
            .catch((error) => {
                Modal.error({
                    title: "Error",
                    content: error.message,
                });
                this.changeLoader(false);
            });
    };

    getAllUsers = (offset = 0, limit = 100) => {
        const params = { offset, limit };

        common_scripts
            .getAllUsers(params)
            .then((res) => {
                const response = res.data;

                if (response.meta.success) {
                    if (offset) {
                        this.mergeAllUsersData(response.data, response.meta);
                    } else {
                        this.setState({ usersData: response });
                    }
                }
            })
            .catch((err) => {
                console.log("get all users error", err);
            });
    };

    mergeAllUsersData = (data = [], meta = {}) => {
        const newData = JSON.parse(JSON.stringify(data));
        const usersData = JSON.parse(JSON.stringify(this.state.usersData));

        //merging two data's with unique values
        let newUsersData = newData.filter((data1) => {
            return !usersData.data.some((data2) => {
                return data1.id === data2.id;
            });
        });
        newUsersData.forEach((data) => {
            data && usersData.data.push(data);
        });

        //incrementing meta count
        const usersDataMeta = usersData.meta;
        usersDataMeta.count = usersData.data.length;
        usersDataMeta.totalCount = meta.totalCount;

        usersData.meta = usersDataMeta;
        this.setState({ usersData });
    };

    getUserDetails = (id, token = undefined) => {
        common_scripts
            .getOneUser(token, id)
            .then((res) => {
                this.setState({ user_details: res.data.data });
            })
            .catch((err) => {
                Modal.error({
                    title: "Error",
                    content: "Error getting user details.Please Try again.",
                });
            });
    };

    getNotes = () => {
        common_scripts
            .getNotes()
            .then((res) => {
                this.setState({ user_notes: res.data.data });
            })
            .catch((err) => {
                Modal.error({
                    title: "Error",
                    content: "Error getting Notes.Please Try again.",
                });
            });
    };

    getTokenDetails = () => {
        return localStorage.getItem("jwt");
    };

    onSearch = (e) => {
        this.changeSearchText(e);
        //make API call and show loader
        this.setSearching(true);
        this.startSearching();
    };

    onReset = () => {
        this.setState({
            showSearchByModal: false,
            search: {
                isSearching: false,
                searchText: "",
                sizeCondition: "true",
                sizeUnit: "MB",
                loading: false,
                searchFilter: "All",
                sortFilters: {
                    params: "",
                },
                searchParams: "",
                error: {},
            },
            searchResultsString: "",
        }, () => this.onSearch(""));
    };

    showMessageModal = (type, title, message) => {
        Modal[type]({
            title: title,
            content: message,
        });
    };

    getSearchResultsString = () => {
        const { search, usersData } = this.state;
        let { searchText, title, description, team, creator, contentType, sizeCondition, size, sizeUnit } = search;
        searchText = searchText && searchText.trim();
        title = title && title.trim();
        description = description && description.trim();
        team = team && team.trim();
        creator = creator && creator.trim();
        contentType = contentType && contentType.trim();
        sizeCondition = sizeCondition && sizeCondition.trim();
        size = Number(size);
        sizeUnit = sizeUnit && sizeUnit.trim();

        let searchResultsString = "";
        if (title) {
            searchResultsString = `${searchResultsString} Title:${title}`;
        }
        if (description) {
            searchResultsString = `${searchResultsString} Description:${description}`;
        }
        if (team) {
            searchResultsString = `${searchResultsString} Team:${team}`;
        }
        if (creator) {
            const user = usersData.data.find(user => user.id === creator);
            searchResultsString = `${searchResultsString} Creator:${user && user.attributes && user.attributes.email}`;
        }
        if (contentType) {
            searchResultsString = `${searchResultsString} Content Type:${contentType}`;
        }
        if (size) {
            searchResultsString = `${searchResultsString} ${sizeCondition === "true" ? "Greater than or equal" : "Less than or equal"}`;
            searchResultsString = `${searchResultsString} ${size}`;
            searchResultsString = `${searchResultsString} ${sizeUnit}`;
        }

        if (searchText) {
            searchResultsString = `${searchResultsString} ${searchText}`;
        }

        return searchResultsString;
    }

    hasSearchByUse = () => {
        const { search } = this.state;
        let {  title, description, team, creator, contentType,  size, } = search;
        title = title && title.trim();
        description = description && description.trim();
        team = team && team.trim();
        creator = creator && creator.trim();
        contentType = contentType && contentType.trim();
        size = Number(size);

        let isUsed = false;
        if (title) isUsed = true;
        if (description) isUsed = true;
        if (team) isUsed = true;
        if (creator) isUsed = true;
        if (contentType) isUsed = true;
        if (size) isUsed = true;

        return isUsed;
    }

    startSearching = (offset = 0, limit = 100) => {
        const { search, } = this.state;
        let {
            searchText,
            title,
            contentType,
            description,
            team,
            creator,
            sizeCondition,
            size,
            sizeUnit,
            sortFilters,
        } = search;
        searchText = searchText && searchText.trim();

        const params = { offset, limit };

        if (searchText) params.text = searchText;
        if (title) params.title = title;
        if (description) params.description = description;
        if (team) params.team = team;
        if (creator) params.creator = creator;
        if (this.state.contentType || contentType) params.type = contentType || this.state.contentType;
        if ((Number(size) || 0) > 0) {
            params.size = toBytes(size, sizeUnit);
            params.greater_than = sizeCondition;
        }
        if (sortFilters.startDate) params.startDate = sortFilters.startDate;
        if (sortFilters.endDate) params.endDate = sortFilters.endDate;
        params.order = sortFilters.order || "descending";

        if (offset === 0) {
            this.changeLoader(true);
            // const searchResultsString = this.getSearchResultsString();
            const searchResultsString = this.hasSearchByUse() ? "Search Query" : searchText;

            this.setState({
                searchResultsString,
                loading: true,
            });
        }

        common_scripts
            .searchContents(params)
            .then((res) => {
                const response = res.data;

                this.changeLoader(false);
                if (response.meta) {
                    if (offset) {
                        this.mergeSearchData(response.data, response.meta);
                    } else {
                        this.setState({
                            searchedData: Object.assign({}, response, {
                                data: response.data.filter((d) => d),
                            }),
                        });
                    }
                } else {
                    this.showMessageModal(
                        "error",
                        "Error",
                        "Error while searching data. Please try again"
                    );
                }
            })
            .catch((err) => {
                this.showMessageModal(
                    "error",
                    "Error",
                    "Error while searching data. Please try again"
                );
                this.changeLoader(false);
            });
    };

    changeLoader = (val) => {
        this.setState({ loading: val });
    };

    changeFilterDrawer = (val) => {
        this.setState({ showFilterDrawer: val });
    };

    changeModalVisibility = (val) => {
        this.setState({ showModal: val });
    };

    changeFilterCategory = (sortFilters = this.state.search.sortFilters.params) => {
        let {search} = this.state;
        search.sortFilters = sortFilters;

        this.setState({ search });
        this.startSearching();
    };

    setSearching = (val, searchParams = {}) => {
        let search = this.state.search;
        search.isSearching = val;
        this.setState({ search });
    };

    changeSearchText = (val) => {
        let search = this.state.search;
        search.searchText = val;
        this.setState({ search });
    };

    mergeSearchData = (data = [], meta = {}) => {
        const newData = JSON.parse(JSON.stringify(data));
        const searchedData = JSON.parse(
            JSON.stringify(this.state.searchedData)
        );

        //merging two data's with unique values
        let newSearchedData;
        newSearchedData = newData.filter((data1) => {
            return !searchedData.data.some((data2) => {
                return data1.id === data2.id;
            });
        });
        newSearchedData.forEach((data) => {
            data && searchedData.data.push(data);
        });

        //incrementing meta count
        const searchedDataMeta = searchedData.meta;
        searchedDataMeta.count = searchedData.data.length;
        searchedDataMeta.totalCount = meta.totalCount;

        searchedData.meta = searchedDataMeta;
        this.setState({ searchedData });
    };

    emailLoginValidation = (email, password) => {
        this.changeLoader(true);
        common_scripts
            .login("user", email, password)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ email });
                    this.changeLoader(false);
                    // localStorage.removeItem('jwt'); //remove token if old data is present
                    localStorage.setItem("jwt", res.data.token); //set new token
                    let validated_token = common_scripts.validateToken();
                    this.getUserDetails(validated_token.id);
                    this.getNotes();
                    this.startSearching();
                    this.getAllUsers();
                    this.changeModalVisibility(false);
                } else {
                    Modal.error({
                        title: "Error",
                        content: res.data.message,
                        onOk: () => {
                            this.changeModalVisibility(true);
                            this.changeLoader(false);
                        },
                    });
                }
            })
            .catch((error) => {
                Modal.error({
                    title: "Error",
                    content: error.message,
                });
                this.changeLoader(false);
            });
    };
    emailSignupValidation = (params) => {
        console.log('--------->input data: ',params)
        this.changeLoader(true);

        common_scripts
            .createSignUpUser("user", params)
            .then((res) => {
                if (res.data.success || (res.data.meta && res.data.meta.success)) {
                    let token = res.data.data.token.token;
                    let otp = res.data.data.otp;
                    let user = res.data.data.authtoken;
                    localStorage.setItem("tempToken", token)
                    localStorage.setItem("tempOtp", otp)
                    localStorage.setItem("authtoken", user)
        //             this.setState({ email });
                    this.changeLoader(false);
                    // localStorage.removeItem('jwt'); //remove token if old data is present
        //             localStorage.setItem("jwt", res.data.token); //set new token
        //             let validated_token = common_scripts.validateToken();
        //             this.getUserDetails(validated_token.id);
        //             this.getNotes();
        //             this.startSearching();
        //             this.getAllUsers();
        //             this.changeModalVisibility(false);
                } else {
                    console.log(res.data.message)
                    Modal.error({
                        title: "Error",
                        content: res.data.message,
                        onOk: () => {
                            this.changeModalVisibility(true);
                            this.changeLoader(false);
                        },
                    });
                }
            })
            .catch((error) => {
                Modal.error({
                    title: "Error",
                    content: error.message,
                });
                this.changeLoader(false);
            });
        // setTimeout(()=>this.changeLoader(false),3000);
    };
    otpValidation = (params) => {
        console.log('--------->input data: ',params, localStorage.getItem("tempOtp"))
        this.changeLoader(true);
                if (localStorage.getItem("tempOtp") === params.otp) {
                    common_scripts
                        .activate_profile( {authtoken: localStorage.getItem("authtoken")})
                        .then((res) => {
                            let token = localStorage.getItem("tempToken");
                            localStorage.removeItem("tempToken")
                            localStorage.removeItem("tempOtp")
                            localStorage.removeItem("authtoken")
                            this.changeLoader(false);
                            console.log(token)
                            localStorage.setItem("jwt", token); //set new token
                            let validated_token = common_scripts.validateToken();
                            this.getUserDetails(validated_token.id);
                            this.getNotes();
                            this.startSearching();
                            this.getAllUsers();
                            this.changeModalVisibility(false);
                        }).catch(err=>{
                        Modal.error({
                            title: "Error",
                            content: err.message,
                            onOk: () => {
                                this.changeModalVisibility(true);
                                this.changeLoader(false);
                            },
                        });
                    })
                } else {
                    Modal.error({
                        title: "Error",
                        content: "Oops! Invalid Otp",
                        onOk: () => {
                            this.changeModalVisibility(true);
                            this.changeLoader(false);
                        },
                    });
                }
    };

    logout = () => {
        console.log("logging out of system");
        localStorage.removeItem("jwt");
        message.info(
            "You have been successfully logged out of the system. Please login again to continue.",
            3
        );
        this.setState({
            search: {
                isSearching: false,
                searchText: "",
                searchFilter: "All",
                searchParams: "",
                sortFilters: {
                    params: "",
                },
            },
            loading: false,
            user_details: null,
            email: null,
            ms_details: null,
            showModal: true,
            showFilterDrawer: false,
            searchedData: [],
        });
        console.log(this.props);
    };

    handleChange = (name) => (value) => {
        this.setState({
            [name]: value,
        });
    };

    handleSearchByModal = (show = false) => {
        show = typeof show === "boolean" && show;
        this.setState({
            showSearchByModal: show,
        });
    };

    render() {
        const {
            loading,
            showModal,
            showSearchByModal,
            search,
            searchResultsString,
            searchedData,
            user_details,
        } = this.state;

        return (
            <Layout
                className="transparent-background"
                style={{ height: "inherit" }}
            >
                <Header
                    userEmail={this.state.email}
                    search={this.state.search}
                    searchResultsString={this.state.searchResultsString}
                    user={user_details}
                    onSearch={this.onSearch}
                    setSearching={this.setSearching}
                    changeSearchText={this.changeSearchText}
                    handleChange={this.handleChange}
                    showMessageModal={this.showMessageModal}
                    handleSearchByModal={() => this.handleSearchByModal(true)}
                    logout={this.logout}
                />

                <Layout.Content
                    className={"transparent-background container-ht"}
                    style={{ minHeight: 280 }}
                >
                    <div
                        className="container"
                        style={{
                            overflowY: "auto",
                            overflowX: "hidden",
                            padding: "10px",
                        }}
                    >
                        <CardDetails
                            loading={loading}
                            search={search}
                            searchResultsString={searchResultsString}
                            searchedData={searchedData}
                            onSearch={this.startSearching}
                            mergeSearchData={this.mergeSearchData}
                            changeFilter={this.changeFilterCategory}
                            user_details={this.state.user_details}
                            user_notes={this.state.user_notes}
                            showMessageModal={this.showMessageModal}
                        />
                    </div>
                </Layout.Content>

                <EmailModal
                    showModal={showModal}
                    loading={showModal ? loading : false}
                    changeModalVisibility={this.changeModalVisibility}
                    microsoftLoginValidation={this.microsoftLoginValidation}
                    emailLoginValidation={this.emailLoginValidation}
                    emailSignupValidation={this.emailSignupValidation}
                    otpValidation={this.otpValidation}
                />

                <SearchByModal
                    showModal={showSearchByModal}
                    search={search}
                    handleChange={this.handleChange("search")}
                    handleModal={this.handleSearchByModal}
                    onSearch={() => this.onSearch("")}
                    onReset={this.onReset}
                    creators={this.state.usersData.data}
                />
            </Layout>
        );
    }
}
