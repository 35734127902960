import React from 'react';
import {Spin} from 'antd';
import ImageContainer from "./image_container";
import api_url from "../common_functions/api_constants";
import axios from "axios/index";
import ErrorFileDownload from "./errorFileDownload";
import DocumentFileContainer from "./document_file_container";
import AudioContainer from "./audio_container";
import VideoContainer from "./video_container";
import ZipFileContainer from './zip_file_container';

export class FileCarousel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loaderPercentage : 0
        }
    }

    componentDidMount(){
        if(this.props.file.id === this.props.selectedFile.id){
            this.props.checkIfBlobURLExists(this.props.selectedIndexFile);
        }
    }

    download = (e, id, filename) => {
        let _this = this;
        axios({
            method: 'GET',
            url: api_url[this.props.url] + '/get-attachment/' + id,
            contentType: 'application/doc; charset=utf-8',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt')},
            responseType: 'arraybuffer',
            onDownloadProgress: function (progressEvent) {
                let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                _this.setState({loaderPercentage : percent});
            }
        }).then(response => {
            let fileDownload = require('js-file-download');
            fileDownload(response.data, filename);
            _this.setState({loaderPercentage : 0});
        }).catch(err=>{
            console.log('Error downloading file',err);
            _this.props.showNotification('error', 'Unable to Download File', err.message);
            _this.setState({loaderPercentage : 0});
        })
    }

    render(){
        if(!this.props.file || this.props.file.error){
            return <ErrorFileDownload
                        file                =   {this.props.file}
                        retry               =   {this.props.file.error}
                        loaderPercentage    =   {this.state.loaderPercentage}
                        download            =   {this.download}
                        getFilePreview      =   {this.props.getFilePreview}
                    />;
        }else if(!this.props.file.blobURL) {
            return <Spin style={{height : '500px', display : 'flex', justifyContent : 'center', alignItems : 'center'}} size="large" />;
        }

        let {imgSet, docSet, textSet, pdfSet, audioSet, videosSet, compressedSet, file} = this.props;

        let element, extension = file.attributes.extension.toLowerCase();

        switch(true){
            case imgSet.has(extension):
                element  = <ImageContainer file={file} />
                break;

            case textSet.has(extension): element = file.element;
                break;

            case pdfSet.has(extension):
            case docSet.has(extension):
                element = <DocumentFileContainer
                                file                =   {file}
                                download            =   {this.download}
                          />;
                break;

            case compressedSet.has(extension):
                element = <ZipFileContainer 
                                file                =   {file}
                                retry               =   {this.props.file.error}
                                loaderPercentage    =   {this.state.loaderPercentage}
                                download            =   {this.download}
                                getFilePreview      =   {this.props.getFilePreview}
                          />;
                break;

            case audioSet.has(extension):
                    element = <AudioContainer
                                    selectedFile        =   {this.props.selectedFile}
                                    file                =   {file}
                                    loaderPercentage    =   {this.state.loaderPercentage}
                                    download            =   {this.download}
                              />;
                break;

            case videosSet.has(extension):
                    element = <VideoContainer
                                    selectedFile        =   {this.props.selectedFile}
                                    file                =   {file}
                                    loaderPercentage    =   {this.state.loaderPercentage}
                                    download            =   {this.download}
                              />;
                break;

            default:
                element = <ErrorFileDownload
                                file                =   {file}
                                loaderPercentage    =   {this.state.loaderPercentage}
                                download            =   {this.download}
                          />;
        }

        return element;
    }
}