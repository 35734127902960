import React from 'react';
import {Row, Col, Input, Icon, Form, Button, Spin, Modal, notification, Divider, Avatar, Select} from 'antd';
import api_url, {TEAMS} from "../common_functions/api_constants";
import common_scripts from "../common_functions/script";


export default class ModalEmailSignin extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            ms_log : "default" // default, login, signup
        }
        this.authHandler = this.authHandler.bind(this);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.ms_log === "signup" && localStorage.getItem('tempOtp')){
            this.changeMSLogin("otp");
        }
    }

    changeMSLogin = (val) => {
        this.setState({ms_log : val});
    }

    checkHeneklDomain = (email) => {
        console.log(email)
        let _email = email.split('@');
        if(_email[_email.length-1] === "henkel.com"){
            return true;
        }
        return false;
    }

    authHandler(err, data){
        // console.log(err, data)
        let verifyHenkelDomain = this.checkHeneklDomain(data.userPrincipalName);
        if(!verifyHenkelDomain){
            return Modal.error({
                title: 'Error',
                content: 'Email address does not exist. Please use henkel verified email.'
            });
        }
        let ms_data = data;
        if(!ms_data.userPrincipalName || ms_data.userPrincipalName.trim() === ""){
            return Modal.error({
                title: 'Error',
                content: 'The email you have provided does not exist. Please provide a valid email-id.'
            });
        }
        this.props.microsoftLoginValidation(ms_data, ms_data.userPrincipalName);
    }

    signUpHandler = (data) => {
        let _email = data.email && data.email.split('@');
        if(_email && _email[_email.length-1] === "henkel.com" || _email[_email.length-1] === "ibism.com" || _email[_email.length-1] === "envigo.co.uk") {
            this.props.emailSignupValidation(data);
        } else {
            return Modal.error({
                title: 'Error',
                content: 'Email address is not valid. Please use your organization email.'
            });
        }

    }
    otpHandler = (data) => {
        this.props.otpValidation(data);
    }

    render(){

        return(
            <Spin spinning={this.props.loading}>
                <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} type="flex" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} span={24}>
                        <div className="heading-text center">
                            <span className="grey-text" style={{fontSize: "1.5em"}}>Welcome to </span>
                            {/*<span className="org-text f-lg">HENKEL</span>*/}
                            <img src="/assests/images/henkel.png" alt="Henkel" style={{height: "40px"}} />
                        </div>
                        <div className="center grey-text f-12" style={{paddingTop : "5px"}}>

                            {this.state.ms_log === "default" && <label><span className="bold">Hello</span>, login with your organisational credentials</label>}
                            {this.state.ms_log === "login" && <label><span className="bold">Hello</span>, login with your organisational credentials</label>}
                            {this.state.ms_log === "signup" && <label><span className="bold">Hi</span>, please provide us with your details to create your account.</label>}
                            {this.state.ms_log === "otp" && <label><span className="bold">Hi</span>, please enter the OTP shared on your registered email.</label>}
                        </div>
                    </Col>
                    {
                        this.state.ms_log === "default" &&
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} span={12} offset={3} style={{padding: "60px 0"}}>
                                {/*<MicrosoftLogin*/}
                                {/*    withUserData        =   {true}*/}
                                {/*    // debug               =   {true}*/}
                                {/*    // buttonTheme         =   "light,light_short,dark,dark_short"*/}
                                {/*    // className           =   "my-custom-class"*/}
                                {/*    graphScopes         =   {api_url.graphScopes}*/}
                                {/*    clientId            =   {api_url.CLIENT_ID}*/}
                                {/*    authCallback        =   {this.authHandler}*/}
                                {/*/>*/}
                                {/*<Divider style = {{right : "45px"}}>*/}
                                {/*    <Avatar size = "small">OR</Avatar>*/}
                                {/*</Divider>*/}
                                <div className="">
                                    <span style={{cursor : "pointer"}} onClick={()=>this.changeMSLogin("login")}><u>Click to login with Email and Password</u></span>
                                </div>
                                <Divider style = {{right : "45px"}}>
                                    <Avatar size = "small">OR</Avatar>
                                </Divider>
                                <div className="">
                                    <span style={{cursor : "pointer"}} onClick={()=>this.changeMSLogin("signup")}><u>Sign up with your Henkel Account</u></span>
                                </div>

                            </Col>

                    }
                    {
                        this.state.ms_log === "login" &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} span={12} offset={0} style={{padding: "15px 0"}}>
                            <WrappedLoginForm
                                changeMSLogin           =   {this.changeMSLogin}
                                emailLoginValidation    =   {this.props.emailLoginValidation}
                            />
                            <div className="center">
                                <span style={{cursor : "pointer"}} onClick={()=>this.changeMSLogin("default")}><u>Go Back</u></span>
                            </div>
                        </Col>
                    }
                    {
                        this.state.ms_log === "signup" &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} span={12} offset={0} style={{padding: "15px 0"}}>
                            <WrappedSignupForm
                                changeMSLogin           =   {this.changeMSLogin}
                                emailSignupValidation    =   {this.signUpHandler}
                            />
                            <div className="center">
                                <span style={{cursor : "pointer"}} onClick={()=>this.changeMSLogin("default")}><u>Go Back</u></span>
                            </div>
                        </Col>
                    }
                    {
                        this.state.ms_log === "otp" &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} span={12} offset={0} style={{padding: "15px 0"}}>
                            <WrappedOTPForm
                                changeMSLogin           =   {this.changeMSLogin}
                                emailSignupValidation    =   {this.otpHandler}
                            />
                            <div className="center">
                                <span style={{cursor : "pointer"}} onClick={()=>this.changeMSLogin("signup")}><u>Go Back</u></span>
                            </div>
                        </Col>
                    }
                </Row>
            </Spin>
        )
    }
}

class Login extends React.Component{

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.submitEmail(values);
            }
        });
    }

    submitEmail = ({email , password}) =>{
        let validEmail = common_scripts.validateEmail(email);
        console.log(email, password)
        if(!validEmail || !email || email.trim() === "" ){
            return notification.error({
                message: 'Invalid Email-ID',
                description: 'Please enter a valid email-id',
            })
        }else if(!password || password.trim() === ""){
            return notification.error({
                message: 'Empty Password',
                description: 'Please enter your password',
            })
        }
        this.props.emailLoginValidation(email, password);
    }

    render(){
        let { getFieldDecorator } = this.props.form;
        return <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email-id!' }],
                })(
                    <Input type="email" allowClear prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <Input.Password allowClear prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                )}
            </Form.Item>
            <Form.Item className="center">
                <Button type="primary" block htmlType="submit" className="login-form-button">
                    Log in
                </Button>
            </Form.Item>
        </Form>
    }
}
const WrappedLoginForm = Form.create({ name: 'normal_login' })(Login);

class Signup extends React.Component{
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.submitEmail(values);
            }
        });
    }

    submitEmail = ({email, name, teams}) =>{
        let validEmail = common_scripts.validateEmail(email);
        console.log(email)
        if(!validEmail || !email || email.trim() === "" ){
            return notification.error({
                message: 'Invalid Email-ID',
                description: 'Please enter a valid email-id',
            })
        }
        this.props.emailSignupValidation({email, name, teams, role:"5ahagxjjgc35o3z"});
        // this.props.changeMSLogin("otp")
    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    render(){
        let { getFieldDecorator } = this.props.form;
        return <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input your name!' }],
                })(
                    <Input type="text" allowClear prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Name" />
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Please input your email-id!' }],
                })(
                    <Input type="email" allowClear prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('teams', {
                    rules: [{ required: true, message: 'Please select your Team!' }],
                })(
                <Select
                    mode={"multiple"}
                    placeholder={"Select team"}
                    onChange={this.handleChange}
                >
                    {TEAMS.map(({ name, id }) => (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
                )}
            </Form.Item>
            <Form.Item className="center">
                <Button type="primary" block htmlType="submit" className="login-form-button">
                    Sign up
                </Button>
            </Form.Item>
        </Form>
    }
}
const WrappedSignupForm = Form.create({ name: 'normal_signup' })(Signup);

class OTP extends React.Component{

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.submitEmail(values);
            }
        });
    }

    submitEmail = ({otp}) =>{
        if(!otp || otp.toString().trim() === "" ){
            return notification.error({
                message: 'Invalid OTP',
                description: 'Please enter a valid otp',
            })
        }
        this.props.emailSignupValidation({otp});
    }
    handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    render(){
        let { getFieldDecorator } = this.props.form;
        return <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
                {getFieldDecorator('otp', {
                    rules: [{ required: true, message: 'Please input your otp!' }],
                })(
                    <Input type="text" allowClear placeholder="OTP" />
                )}
            </Form.Item>

            <Form.Item className="center">
                <Button type="primary" block htmlType="submit" className="login-form-button">
                    Verify
                </Button>
            </Form.Item>
        </Form>
    }
}
const WrappedOTPForm = Form.create({ name: 'normal_otp' })(OTP);
//Old Concept File
export class LoginNormal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email       :   "",
            password    :   ""
        }
    }

    onBlurEmail = (e) => {
        let validEmail = common_scripts.validateEmail(e.target.value);
        if(!validEmail){
            return e.target.classList.add("border-error");
        }

        e.target.classList.remove("border-error");
    }

    onBlurPassword = (e) => {
        if(!e.target.value || e.target.value.trim() === ""){
            return e.target.classList.add("border-error");
        }

        e.target.classList.remove("border-error");
    }

    changeEmail = (e)=>{
        this.setState({email : e.target.value});
    }

    changePassword = (e)=>{
        this.setState({password : e.target.value});
    }

    submitEmail = () =>{
        let validEmail = common_scripts.validateEmail(this.state.email);
        console.log(this.state.email, this.state.password)
        if(!validEmail || !this.state.email || this.state.email.trim() === "" ){
            return notification.error({
                message: 'Invalid Email-ID',
                description: 'Please enter a valid email-id',
            })
        }else if(!this.state.password || this.state.password.trim() === ""){
            return notification.error({
                message: 'Empty Password',
                description: 'Please enter your password',
            })
        }
        this.props.emailLoginValidation(this.state.email, this.state.password);
    }

    render(){
        return (
            <div>
                <Input
                    // defaultValue    =   {this.props.email}
                    type            =   "email"
                    id              =   "emailId"
                    size            =   "default"
                    placeholder     =   "Enter your email"
                    required
                    allowClear
                    prefix          =   {<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange        =   {e=>this.changeEmail(e)}
                    onBlur          =   {e=>this.onBlurEmail(e)}
                />
                <Input.Password
                    // defaultValue    =   {this.props.password}
                    type            =   "password"
                    id              =   "pd_lite"
                    size            =   "default"
                    placeholder     =   "Enter your password"
                    style           =   {{marginTop : "10px"}}
                    required
                    allowClear
                    prefix          =   {<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    onChange        =   {e=>this.changePassword(e)}
                    onBlur          =   {e=>this.onBlurPassword(e)}
                />
                <center>
                    <Button
                        className   =   "org-background"
                        type        =   "primary"
                        size        =   "default"
                        htmlType    =   "submit"
                        style       =   {{marginTop:"10px", height: "28px"}}
                        onClick     =   {()=>this.submitEmail()}
                    >Login</Button>
                </center>
            </div>
        )
    }
}