import React from 'react';

export default class AudioContainer extends React.Component{

    componentDidUpdate(nxtProps, nxtState){
        if(nxtProps.file.id !== nxtProps.selectedFile.id){
            let audioDocument = document.getElementById(`audio_${nxtProps.file.id}`);
            audioDocument.pause();
        }
    }

    render(){
        let {file} = this.props;
        return (
            <div className="audio-file-preview">
                <audio id={`audio_${file.id}`} autoPlay width="100%" height="80%" controls style={{marginTop: "46%", width : "95%"}} controlsList="nodownload">
                    <source src={file.blobURL} type={`audio/${file.attributes.extension}`}></source>
                    Your browser doesn't support HTML5 video in WebM with VP8/VP9 or MP4 with H.264.
                </audio>
            </div>
        )
    }

}