import React from 'react'
import { Spin, Alert } from 'antd';

const LoadMore = (props) => {
    return <Spin spinning={props.showLoader}>
        {
            props.showLoaderButton &&
                <span onClick = {props.loadMoreData}>
                    <Alert style        =   {{textAlign:'center', cursor:'pointer', backgroundColor : 'rgb(234, 26, 36)', color : '#ffffff'}}
                           message      =   {`Load more ${props.name}`}
                           type         =   "info"
                           className    =   "bold"
                    />
                </span>
        }
    </Spin>;
}
export default LoadMore;