import React from 'react';
import CardDetails from "../modules/search/card_details";
import SearchComponent from "../modules/search/search_component";

export default class MainContainer extends React.Component{

    render(){
        let {search, loading, userEmail, searchedData} = this.props;

        loading = !this.props.showModal ? loading : false;
        let overflow = search.isSearching ? {overflowY: "auto",overflowX: "hidden", padding: "10px"} : {};
        return(
            <div className="container" style={{...overflow}}>
                {
                    search.isSearching ?
                        <CardDetails
                            loading             =   {loading}
                            search              =   {search}
                            searchedData        =   {searchedData}
                            mergeSearchData     =   {this.props.mergeSearchData}
                            changeFilter        =   {this.props.changeFilter}
                            user_details        =   {this.props.user_details}
                            user_notes          =   {this.props.user_notes}
                        />
                        :
                        <SearchComponent
                            onSearch            =   {this.props.onSearch}
                            search              =   {search}
                            userEmail           =   {userEmail}
                            loading             =   {loading}
                            shadowClass         =   "header-search-shadow"
                            searchFilterClass   =   "search-filter"
                            changeFilter        =   {this.props.changeFilter}
                            setSearching        =   {this.props.setSearching}
                            changeSearchText    =   {this.props.changeSearchText}
                            showMessageModal    =   {this.props.showMessageModal}
                        />
                }
            </div>
        )
    }
}