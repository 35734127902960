let BASE_URL, url, env, teams, content_workflow_id, content_template_id, client_id, file_field_id;
url = window.location.href;
// switch(true){
//     case url.startsWith('https://search-henkel.ibism.com'):
        content_template_id = 'fd9sc4ctjpb1np3e';
        content_workflow_id = 'fd9sc4ctjpb1pwfi';
        client_id = '56f09d49-d9ce-41fe-869b-fc38b58deed9';
        file_field_id = '1543986421063';
        teams = [
            {name : 'Global Marcom', id : "5ahabmnjgoqnlvd"},
            {name : 'India', id : "fd9sc5geju80wilc"},
            {name : 'China', id : "fd9sc5geju80w98e"},
            {name : 'Germany', id : "fd9sc5geju80vwfd"},
            {name : 'USA', id : "fd9sc5geju80tjpz"},
            {name : 'Australia', id : "fd9scj9uk3eap0q9"},
            {name : 'Europe', id : "fd9sc90ml5t5m793"},
        ];
        env = "https://henkel.ibism.com";
        BASE_URL = 'https://frigga.ibism.com';//search live
//         break;
//     case url.startsWith('https://henkel-search.ibism.com'):
//         content_template_id = '5ahalscjqoukrud';
//         content_workflow_id = '5ahalscjqouxj3e';
//         client_id = 'f3fcbbfd-1453-4206-ae8d-055a48a6188d';
//         file_field_id = '1547017102838';
//         teams = [
//             {name : 'Global Marcom', id : "5ahabmnjgoqnlvd"},
//             {name : 'India', id : "5ahak5gjt8fmu8t"},
//             {name : 'China', id : "5ahahvnjny456xu"},
//             {name : 'Germany', id : "5aha7bbjmsqia5v"},
//             {name : 'USA', id : "5aha7bbjmsqhnts"},
//             {name : 'Australia', id : "fd9scj9uk3eap0q9"},
//             {name : 'Europe', id : "fd9sc90ml5t5m793"},
//         ];
//         env = "http://henkel-staging.ibism.com";
//         BASE_URL = 'https://carol.ibism.com';
//         break;
//
//     default:
//         content_template_id = '5ahalscjqoukrud';
//         content_workflow_id = '5ahalscjqouxj3e';
//         client_id = 'f3fcbbfd-1453-4206-ae8d-055a48a6188d';
//         file_field_id = '1547017102838';
//         teams = [
//             {name : 'Global Marcom', id : "5ahabmnjgoqnlvd"},
//             {name : 'India', id : "5ahak5gjt8fmu8t"},
//             {name : 'China', id : "5ahahvnjny456xu"},
//             {name : 'Germany', id : "5aha7bbjmsqia5v"},
//             {name : 'USA', id : "5aha7bbjmsqhnts"},
//             {name : 'Australia', id : "fd9scj9uk3eap0q9"},
//             {name : 'Europe', id : "fd9sc90ml5t5m793"},
//         ];
//         env = "http://henkel-staging.ibism.com";
//         BASE_URL = 'https://carol.ibism.com'; // search staging
// }

let API_BASE_URL = `${BASE_URL}/api/v1/`;

let api_url = {
    ADMIN_NAME          : 'Anshuman',
    ADMIN_EMAIL         : 'anshuman@ibism.com',
    ADMIN_PASSWORD      : 'ibism',
    STAGING_ENV         : 'http://henkel-staging.ibism.com',
    PRODUCTION_ENV      : 'https://henkel.ibism.com',
    CLIENT_ID           : client_id,//new henkel client id -- Amit Account
    APPLICATION_PWD     : '',//application password
    graphScopes         : ["user.read"],
    FILE_FIELD_ID       : file_field_id,
    PEOPLE_URL          : API_BASE_URL + 'users',
    SIGNUP_PEOPLE_URL          : API_BASE_URL + 'signup/users',
    ACTIVATE_URL          : API_BASE_URL + 'activate',
    LOGIN_URL           : API_BASE_URL + 'login',
    GET_USER_EMAIL      : API_BASE_URL + 'user/email',
    TASK_URL            : API_BASE_URL + 'tasks',
    NOTES_URL           : API_BASE_URL + 'notes',
    TEMPLATE_URL        : API_BASE_URL + 'templates',
    WORKFLOW_URL        : API_BASE_URL + 'workflows',
    SEARCH_URL          : API_BASE_URL + 'search',
    SEARCH_CONTENTS_URL : API_BASE_URL + 'contents/search',
    RAISED_CONTENTS_REQUEST_URL : API_BASE_URL + 'contents/raised-request',
    MS_LOGIN            : API_BASE_URL + 'ms_login',
    ATTACHMENTS         : API_BASE_URL + 'attachments',
}
export default api_url;
export const ENV = env;
export const TEAMS = teams;
export const CONTENT_WORKFLOW_ID = content_workflow_id;
export const CONTENT_TEMPLATE_ID = content_template_id;