import api_url from "./api_constants";
import axios from "axios/index";
import jwt_decode from 'jwt-decode';
import moment from 'moment';

let common_scripts = {

    validateToken : function(){
        let dateNow = new Date();
        if (jwt_decode(localStorage.getItem('jwt')).exp < dateNow.getTime()) {
            return false;
        }
        return jwt_decode(localStorage.getItem('jwt'));
    },

    guid_generator : function(){
        let timestamp   = parseInt(moment().utc().valueOf());
        return timestamp + Math.floor(Math.random() * 1000 + 1);
    },

    validateEmail : (email) => {
        let filter = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return filter.test(email);
    },

    getFilterCategory : (category) => {
        let type = '';
        switch(category){
            case 'Source File': type = 'source_file';
                break;
            case 'Video': type = 'video';
                break;
            case 'Gif': type = 'gif';
                break;
            case 'Infographic': type = 'infographic';
                break;
            case 'Image Sets': type = 'image_sets';
                break;
            case 'Presentation': type = 'presentation';
                break;
            case 'Emailer': type = 'emailer';
                break;
            case 'Feedback': type = 'feedback_file';
                break;
            case '3D Model': type = '3d_model';
                break;
            case 'Localisation': type = 'localisation';
                break;
            default : type = '';
        }
        return type;
    },

    login : function(type, email, password){
        let user_details = { email: api_url.ADMIN_EMAIL, password: api_url.ADMIN_PASSWORD }
        if(type === "user"){
            user_details = { email: email, password: password }
        }
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'POST',
                url     : api_url.LOGIN_URL,
                data    : user_details
            }).then(res=>{
                resolve(res)
            }).catch(err=>{
                reject(err);
            })
        })
    },

    // verifyEmail : function(email){
    //     let data = {
    //         data : {
    //             attributes : {
    //                 email
    //             }
    //         }
    //     };
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method  : 'POST',
    //             url     : api_url.GET_USER_EMAIL,
    //             data
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         })
    //     })
    // },

    ms_login : function(data){
        return new Promise((resolve, reject) => {
            axios({
                method  : 'POST',
                headers : {'Authorization': 'Bearer '},
                url     : api_url.MS_LOGIN,
                data    : data
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },
    activate_profile : function(data){
        return new Promise((resolve, reject) => {
            axios({
                method  : 'POST',
                headers : {'Authorization': 'Bearer '},
                url     : api_url.ACTIVATE_URL,
                data    : data
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },

    getAllUsers : (params = {}) => {
        const token = localStorage.getItem('jwt');

        return new Promise(function(resolve, reject){
            axios({
                method: "GET",
                url : api_url.PEOPLE_URL,
                headers: {"Authorization": `Bearer ${token}`},
                params,
            })
            .then(response => resolve(response))
            .catch(err => reject(err));
        });
    },

    getOneUser : function(token = localStorage.getItem('jwt'), userId){
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'GET',
                url     : api_url.PEOPLE_URL + `/${userId}`,
                headers : {'Authorization': 'Bearer '+ token}
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    getNotes : function(token = localStorage.getItem('jwt')){
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'GET',
                url     : api_url.NOTES_URL,
                headers : {'Authorization': 'Bearer '+ token}
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    updateNotes : function(data, noteId, token = localStorage.getItem('jwt')){
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'PUT',
                url     : api_url.NOTES_URL + `/${noteId}`,
                headers : {'Authorization': 'Bearer '+ token},
                data    : data
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    getAssginmentHint : function(teams, users, no_of_days = "7", method = "soonest", effort = "1"){
        let endDate = moment().add(no_of_days, 'days').format();
        let currentDate = moment().format();
        let assignmentHint = {
            data: {
                attributes: {
                    assigned_team: teams,
                    assigned_user: users,
                    assignment_method: method,
                    duration: {
                        effort: effort,
                        to: endDate,
                        from: currentDate
                    }
                }
            }
        };
        return assignmentHint;
    },

    createTaskData : function(wfId, currentThreadName, taskStatus, templateId, templateName, fields, assignment_method, assignmentHintData, description, user_id, user_team, slots){
        let task_details = {
            data: {
                attributes: {
                    threadName: currentThreadName,
                    threadTags: [],
                    name: currentThreadName,
                    description: description,
                    status_description : taskStatus,
                    workflowId: wfId,
                    manual_exec_list: null,
                    tags: [],
                    shared_with: null,
                    template: {
                        id: templateId,
                        name: templateName,
                        fields: fields
                    },
                    note_owner : user_id,//only in case of creating first task ele null
                    assignment_method: assignment_method,
                    assigned_user: (assignmentHintData && assignmentHintData.attributes.user_id) || user_id,
                    assigned_team: (assignmentHintData && assignmentHintData.attributes.user_team) || user_team,
                    slots: (assignmentHintData && assignmentHintData.attributes.slots) || slots,
                    aliases: {
                        "Triggered": {
                            "alias": "Triggered",
                            "action": "Trigger",
                            "status": true
                        },
                        "Assigned": {
                            "alias": "Assigned",
                            "action": "Assign",
                            "status": true
                        },
                        "Accepted": {
                            "alias": "Accepted",
                            "action": "Accept",
                            "status": false
                        },
                        "Started": {
                            "alias": "Started",
                            "action": "Start",
                            "status": true
                        },
                        "Closed": {
                            "alias": "Closed",
                            "action": "Close",
                            "status": true
                        },
                        "Declined": {
                            "alias": "Declined",
                            "action": "Decline",
                            "status": true
                        },
                        "Failed": {
                            "alias": "Failed",
                            "action": "Fail",
                            "status": true
                        },
                        "Canceled": {
                            "alias": "Canceled",
                            "action": "Cancel",
                            "status": true
                        }
                    }
                }
            }
        }
        return task_details;
    },

    createSignUpUser : function(token = localStorage.getItem('jwt'), data){
        return new Promise(function(resolve, reject){
            axios({
                method  : 'POST',
                headers : {'Authorization': 'Bearer ' + token },
                url     : api_url.SIGNUP_PEOPLE_URL,//users
                data    : {data: {attributes: data}}
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    // updateUser : function(token = localStorage.getItem('jwt'), userId, data){
    //     return new Promise(function(resolve, reject){
    //         axios({
    //             method  : 'PUT',
    //             headers : {'Authorization': 'Bearer ' + token },
    //             url     : api_url.PEOPLE_URL + `/${userId}`,//users
    //             data    : data
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         });
    //     })
    // },

    // getAllTasksWithFilters : function(token = localStorage.getItem('jwt'), filters){
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method  : 'GET',
    //             headers : {'Authorization': 'Bearer ' + token},
    //             url     : api_url.TASK_URL + `?default=false&${filters}&skip=0&limit=0`
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         });
    //     });
    // },

    // closeTask : function(token = localStorage.getItem('jwt'), taskId, data){
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method  : 'PUT',
    //             headers : {'Authorization': 'Bearer ' + token},
    //             url     : api_url.TASK_URL + `/${taskId}/update-status`,
    //             data    : data
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         });
    //     });
    // },

    // updateTask : function(token = localStorage.getItem('jwt'), taskId, data){
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method  : 'PUT',
    //             headers : {'Authorization': 'Bearer ' + token},
    //             url     : api_url.TASK_URL + `/${taskId}`,
    //             data    : data
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         });
    //     });
    // },

    updateTaskStatus : function(token = localStorage.getItem('jwt'), taskId, data){
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'PUT',
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.TASK_URL + `/${taskId}/update-status`,
                data    : data
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },

    createTask : function(token = localStorage.getItem('jwt'), task){
        return new Promise(function(resolve, reject){
            axios({
                method  : 'POST',
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.TASK_URL,
                data    : task
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    // uploadTaskAttachments : function(token = localStorage.getItem('jwt'), formData){
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method  : 'POST',
    //             headers : {
    //                 'Authorization': 'Bearer ' + token,
    //                 'Content-Type': 'multipart/form-data'
    //             },
    //             url     : api_url.TASK_URL + '/upload-attachment',
    //             formData: formData
    //         }).then(res=>{
    //             resolve(res);
    //         }).catch(err=>{
    //             reject(err);
    //         });
    //     })
    // },

    assignmentHint : function(token = localStorage.getItem('jwt'), assignmentHint){
        return new Promise(function (resolve, reject) {
            axios({
                method  : 'POST',
                headers : {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                url     : api_url.TASK_URL + '/user-assignment-hint',
                data    : assignmentHint
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    // downloadTaskFile : function(token, fileId){
    //     return new Promise(function (resolve, reject) {
    //         axios({
    //             method: 'GET',
    //             url: api_url.TASK_URL + '/get-attachment/' + fileId,
    //             contentType: 'application/doc; charset=utf-8',
    //             headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt')},
    //             responseType: 'arraybuffer',
    //         }).then((screenData) => {
    //             resolve(screenData);
    //         }).catch(err => {
    //             reject(err);
    //         });
    //     });
    // },

    getOneTemplate : function (token = localStorage.getItem('jwt'), templateId){
        return new Promise(function (resolve, reject) {
            axios({
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                url: api_url.TEMPLATE_URL + `/${templateId}`
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },

    getOneWorkflow : function(token = localStorage.getItem('jwt'), id){
        return new Promise(function (resolve, reject) {
            axios({
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                url: api_url.WORKFLOW_URL + `/${id}`
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    },

    // getOneBusinessTemplate : function (token, templateId){
    //     return new Promise(function (resolve, reject) {
    //         request.get({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token,
    //             },
    //             url: process.env.IBISM_URL + `/business-object-templates/${templateId}`
    //         }, function (err, respon, res) {
    //             if (err) {
    //                 reject(err);
    //             } else {
    //                 resolve(res);
    //             }
    //         });
    //     });
    // },
    //
    // getBusinessEntries : function(token, filters){
    //     let url = process.env.IBISM_URL + `/drawers`;//business-objects
    //     if(filters){
    //         url = url + `${filters}`;
    //     }
    //
    //     return new Promise(function (resolve, reject) {
    //         request.get({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token,
    //             },
    //             url
    //         }, function (err, respon, res) {
    //             if (err) {
    //                 reject(err);
    //             } else {
    //                 resolve(res);
    //             }
    //         });
    //     });
    // },
    //
    // createBusinessEntries : function (token, data){
    //     return new Promise(function (resolve, reject) {
    //         request.post({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token,
    //             },
    //             url : process.env.IBISM_URL + '/drawers',//business-objects
    //             body : JSON.stringify(data)
    //         }, function (err, respon, res) {
    //             if (err) {
    //                 reject(err);
    //             } else {
    //                 resolve(res);
    //             }
    //         });
    //     });
    // },
    //
    // updateBusinessEntries : function (token, id, data){
    //     return new Promise(function (resolve, reject) {
    //         request.put({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token,
    //             },
    //             url : process.env.IBISM_URL + '/drawers/'+id,//business-objects
    //             body : JSON.stringify(data)
    //         }, function (err, respon, res) {
    //             if (err) {
    //                 reject(err);
    //             } else {
    //                 resolve(res);
    //             }
    //         });
    //     });
    // },
    //
    // getOneThread : function(token, threadId){
    //     return new Promise(function(resolve, reject){
    //         request.get({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //             url: process.env.IBISM_URL + `/threads/${threadId}`
    //         }, function(err, respon, res){
    //             if(err){
    //                 reject(err);
    //             }else{
    //                 resolve(res);
    //             }
    //         })
    //     })
    // },
    //
    // getTeam : function(token, filters){
    //     return new Promise(function(resolve, reject){
    //         request.get({
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //             url: process.env.IBISM_URL + `/xterior${filters}`//teams
    //         }, function(err, respon, res){
    //             if(err){
    //                 reject(err);
    //             }else{
    //                 resolve(res);
    //             }
    //         })
    //     })
    // },

    search : function(token = localStorage.getItem('jwt'), filters){
        return new Promise(function(resolve, reject){
            axios({
                method  : 'GET',
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.SEARCH_URL +`?skip=0&${filters}`,
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    loadMoreSearchedData : function(token = localStorage.getItem('jwt'), filters, limit, offset){
        return new Promise(function(resolve, reject){
            axios({
                method  : 'GET',
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.SEARCH_URL +`?limit=${limit}&offset=${offset}&${filters}`,
            }).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        })
    },

    searchContents : (params = {}) => {
        const token = localStorage.getItem('jwt');

        return new Promise(function(resolve, reject){
            axios({
                method  : 'GET',
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.SEARCH_CONTENTS_URL,
                params  : { limit: 100, offset: 0, ...params }
            })
            .then(res => resolve(res))
            .catch(err => reject(err));
        })
    },

    raiseContentRequests : (payload = {}) => {
        const token = localStorage.getItem('jwt');

        return new Promise(function(resolve, reject) {
            axios({
                method  : "POST",
                headers : {'Authorization': 'Bearer ' + token},
                url     : api_url.RAISED_CONTENTS_REQUEST_URL,
                data    : payload
            })
            .then(res => resolve(res))
            .catch(err => reject(err));
        })
    },

    getFilePreview : function(api_url, token = localStorage.getItem('jwt')){
        return new Promise(function(resolve, reject){
            axios({
                method: 'GET',
                url : api_url,
                // contentType: 'application/doc; charset=utf-8',
                headers: {'Authorization': 'Bearer ' + token},
                responseType: 'blob',
            }).then(response => {
                resolve(response);
            }).catch(err=>{
                reject(err);
            })
        });
    },

    updateFileParams : function(filterParams){
        return new Promise(function(resolve, reject){
            axios({
                method: 'PUT',
                url : `${api_url.ATTACHMENTS}/params?${filterParams}`,
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt')},
            }).then(response => {
                resolve(response);
            }).catch(err=>{
                reject(err);
            })
        });
    },

    getFileDetailsFromTask : function(fileId){
        return new Promise(function(resolve, reject){
            axios({
                method: 'GET',
                url : `${api_url.ATTACHMENTS}/task_details/${fileId}`,
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwt')},
            }).then(response => {
                resolve(response);
            }).catch(err=>{
                reject(err);
            })
        });
    }

};

export default common_scripts;