import React from 'react';
import {Drawer, Radio, Icon} from 'antd';

let radioStyle = {
    display: 'block',
    height: '40px',
    lineHeight: '30px',
};

export default class FilterDrawer extends React.Component{

    changeFilter = (e) => {
        this.props.changeFilter(e.target.value);
        this.props.changeFilterDrawer(false)
    }

    render(){
        let { search, showFilterDrawer } = this.props;
        return(
            <Drawer
                title           =   "More Category Filters"
                placement       =   "right"
                closable        =   {false}
                onClose         =   {e=>this.props.changeFilterDrawer(false)}
                visible         =   {showFilterDrawer}
            >
                <Radio.Group onChange={e=>this.changeFilter(e)} value={search.searchFilter}>
                    <Radio style={radioStyle} value='All'>
                        <Icon type='folder-open' style={{fontSize:'19px',color:'#E20015',marginRight:'10px'}} />All
                    </Radio>
                    <Radio style={radioStyle} value='Source File'>
                        <Icon type='file' style={{fontSize:'19px',color:'#E20015',marginRight:'10px'}} />Source File
                    </Radio>
                    <Radio style={radioStyle} value='Video'>
                        <img alt='' src='assests/images/video_1.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-2px'}} />Video
                    </Radio>
                    <Radio style={radioStyle} value='Gif'>
                        <img alt='' src='assests/images/gif_1.png' style={{width:'22px',marginRight:'10px',verticalAlign:'-5px'}} />Gif
                    </Radio>
                    <Radio style={radioStyle} value='Infographic'>
                        <img alt='' src='assests/images/infographic.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-6px'}} />Infographic
                    </Radio>
                    <Radio style={radioStyle} value='Image Sets'>
                        <img alt='' src='assests/images/image sets.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-3px'}} />Image Sets
                    </Radio>
                    <Radio style={radioStyle} value='Presentation'>
                        <img alt='' src='assests/images/presentation.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-4px'}} />Presentation
                    </Radio>
                    <Radio style={radioStyle} value='Emailer'>
                        <img alt='' src='assests/images/Emailer.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-11px'}} />Emailer
                    </Radio>
                    <Radio style={radioStyle} value='Feedback'>
                        <Icon type='solution' style={{fontSize:'19px',color:'#E20015',marginRight:'10px'}} />Feedback
                    </Radio>
                    <Radio style={radioStyle} value='3D Model'>
                        <img alt='' src='assests/images/3D Model.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-4px'}} />3D Model
                    </Radio>
                    <Radio style={radioStyle} value='Localisation'>
                        <img alt='' src='assests/images/Localisation.png' style={{width:'25px',marginRight:'10px',verticalAlign:'-4px'}} />Localisation
                    </Radio>
                </Radio.Group>
            </Drawer>
        )
    }
}