import React from 'react';
import {Row, Col, Button} from 'antd';
import {Link} from 'react-router-dom';

export const NotFound = () =>{
    return <Row gutter={{xs: 16, sm: 16, md: 8, lg: 8}} type="flex" justify="center" className="content-center">
        <Col>
            <div className="imgBlock">
                <h1 className="bold">Requested URL not found</h1>
            </div>
            <div className="description" style={{marginBottom:"20px"}}>
                The Requested URL doesn't exist. Click on the button to go back.
            </div>
            <div className="redirect-block">
                <Button type="primary" size="large"><Link to='/search'>Back to Home</Link></Button>
            </div>
        </Col>
    </Row>
}
