export const fileTypes = {
    source_file: {
        id: "source_file",
        name: "Source File",
    },
    video: {
        id: "video",
        name: "Video",
    },
    gif: {
        id: "gif",
        name: "Gif",
    },
    infographic: {
        id: "infographic",
        name: "Infographic",
    },
    image_sets: {
        id: "image sets",
        name: "Image sets",
    },
    presentation: {
        id: "presentation",
        name: "Presentation",
    },
    emailer: {
        id: "emailer",
        name: "Emailer",
    },
    "3d_model": {
        id: "3D model",
        name: "3D Model",
    },
    "pdf": {
        id: "pdf",
        name: "PDFs",
    },
    localisation: {
        id: "localisation",
        name: "Localisation",
    },
    lab: {
        id: "lab",
        name: "Lab",
    },
}

export const readableFileSize = (bytes, si) => {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }
    var units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
};

export const toBytes = (size = 0, type = "Bytes") => {
    const types = ["BYTES", "KB", "MB", "GB", "TB"];

    const key = types.indexOf(type.toUpperCase());
    if (typeof key !== "boolean") {
        return size * 1024 ** key;
    }

    return "invalid type: type must be GB/KB/MB etc.";
};

export const getTeamFlag = (team = {}) => {
    const teamName = team && team.name.toLowerCase();

    if (teamName === "india") return "india.png";
    else if (teamName === "china") return"china.png";
    else if (teamName === "germany") return"germany.png";
    else if (teamName === "australia") return "australia.png";
    else if (teamName === "usa") return "usa.png";
    else if (teamName === "europe") return "europe.png";
    else return "henkel.png";
}