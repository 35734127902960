import React from 'react';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import ErrorFileDownload from './errorFileDownload';
import {List} from 'antd';

export default class ZipFileContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            zipDetails : null
        }
    }

    componentDidMount(){
        JSZipUtils.getBinaryContent(this.props.file.blobURL, (err, file_data) => {
            if(err) {
                throw err; // or handle err
            }else{
                let zipArray = [];
                JSZip.loadAsync(file_data).then(zip => {
                    console.log('file read displaying data', zip)
                    zip.forEach((relativePath, zipEntry, index) => {  // 2) print entries
                        // console.log(zipEntry, relativePath)
                        console.log(index)
                        zipArray.push({...zipEntry});
                    })
                    this.setState({zipDetails : zipArray});
                }).catch(err=>{
                    console.log('Error reading file', err);
                })
            }
        });
    }

    render(){
        let {file} = this.props;    
        let {zipDetails} = this.state;
        
        console.log('final zipDetails', zipDetails);
       return (
            Array.isArray(zipDetails) ?
                <List
                    style           =   {{textAlign : 'left'}}
                    size            =   "large"
                    // header={<div>Header</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource      =   {zipDetails}
                    renderItem      =   {item => (<List.Item>{item.name}</List.Item>)}
                />
                :
                <ErrorFileDownload 
                    file                =   {file}
                    loaderPercentage    =   {this.props.loaderPercentage}
                    download            =   {this.props.download}
                />
       )
    }
}