import React from 'react';
import { Layout, Modal } from 'antd';
import MainContainer from "./main_container";
import ModalEmailSignin from "../modules/util/email_signin";
import FilterDrawer from "../modules/search/filter_drawer";

export default class LayoutContent extends React.Component{

    render(){
        let {search, loading, userEmail, searchedData} = this.props;
        let container = search.isSearching ? 'container-ht' : '';
        return <Layout.Content className={`transparent-background ${container}`} style={{minHeight: 280}}>
            <EmailModal
                showModal                   =   {this.props.showModal}
                loading                     =   {this.props.showModal ? loading : false}
                changeModalVisibility       =   {this.props.changeModalVisibility}
                microsoftLoginValidation    =   {this.props.microsoftLoginValidation}
                emailLoginValidation        =   {this.props.emailLoginValidation}
            />
            <FilterDrawer
                showFilterDrawer    =   {this.props.showFilterDrawer}
                changeFilterDrawer  =   {this.props.changeFilterDrawer}
                changeFilter        =   {this.props.changeFilter}
                search              =   {search}
            />
            <MainContainer
                search              =   {search}
                userEmail           =   {userEmail}
                searchedData        =   {searchedData}
                showModal           =   {this.props.showModal}
                onSearch            =   {this.props.onSearch}
                user_details        =   {this.props.user_details}
                user_notes          =   {this.props.user_notes}
                loading             =   {loading}
                changeFilter        =   {this.props.changeFilter}
                setSearching        =   {this.props.setSearching}
                changeSearchText    =   {this.props.changeSearchText}
                showMessageModal    =   {this.props.showMessageModal}
                mergeSearchData     =   {this.props.mergeSearchData}
            />
        </Layout.Content>
    }
}

const EmailModal = (props) => {
    return <Modal
        visible             =   {props.showModal}
        onOk                =   {()=>props.changeModalVisibility(false)}
        footer              =   {false}
        closable            =   {false}
        destroyOnClose      =   {true}
        bodyStyle           =   {{height : "400px"}}
        width               =   {620}
        style               =   {{ top: 150 }}
    >
        <ModalEmailSignin
            loading                     =   {props.loading}
            microsoftLoginValidation    =   {props.microsoftLoginValidation}
            emailLoginValidation        =   {props.emailLoginValidation}
        />
    </Modal>
}