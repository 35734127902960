import React from 'react';
import { Layout, Icon, Row, Col, Button, Menu, Dropdown, Avatar } from 'antd';
import SearchDirectory from "../modules/search/search_directory";
import {ENV} from "../modules/common_functions/api_constants";

export default class LayoutHeader extends React.Component{
    render(){
        let {search, fixedHeader, user, userEmail} = this.props;
        let thumb, name, email, role;
        if(user){
            name    = user.attributes.name;
            email   = user.attributes.email;
            thumb   = user.attributes.avatar_thumb;
            role    = user.relationships.role.data.name;
        }

        let path = '/app/0/myDashboard';
        switch(role){
            case 'Manager'      : path = "/app/1/myDashboard"; break;
            case 'Member'       : path = "/app/2/myDashboard"; break;
            case 'Freelancer'   : path = "/app/3/myDashboard"; break;
            default : path = "/app/0/myDashboard"; break;
        }
        let dashboardUrl = `${ENV}/authenticate?tk=${localStorage.getItem('jwt')}&url=${path}`;

        let paddingTop = search.isSearching ? {paddingTop: "10px"} : {};
        return <Layout.Header className={`${fixedHeader} transparent-background`}>
            <Row gutter={{xs: 8, sm: 8, md: 8, lg: 8}} type="flex" justify="space-between" style={paddingTop}>
                <Col xs={search.isSearching ? 0 : 12} sm={search.isSearching ? 0 : 12} md={2} lg={2} xl={2} xxl={2} span={2} className={!search.isSearching ? "pull-left" : ""}>
                    <a href="/"><img src="/assests/images/henkel.png" alt="Henkel" style={{height: "40px"}} /></a>
                </Col>
                {
                    search.isSearching ?
                        <Col xs={24} sm={24} md={20} lg={18} xl={18} xxl={16} span={18}>
                            <SearchDirectory
                                userEmail           =   {userEmail}
                                searchFilterClass   =   "header-search"
                                search              =   {search}
                                onSearch            =   {this.props.onSearch}
                                changeFilter        =   {this.props.changeFilter}
                                setSearching        =   {this.props.setSearching}
                                changeSearchText    =   {this.props.changeSearchText}
                                showMessageModal    =   {this.props.showMessageModal}
                            />
                        </Col>
                        :
                        <Col xs={0} sm={0} md={3} lg={3} xl={3} xxl={3} span={3} offset={!search.isSearching ? 15 : 0}>
                            <DashboardButton
                                isSearching     =   {search.isSearching}
                                dashboardUrl    =   {dashboardUrl}
                            />
                        </Col>
                }
                <Col xs={search.isSearching ? 0 : 12} sm={search.isSearching ? 0 : 12} md={6} lg={4} xl={4} xxl={4} span={4}>
                    <LoggedUserDetails
                        isSearching     =   {search.isSearching}
                        name            =   {name}
                        email           =   {email}
                        role            =   {role}
                        thumb           =   {thumb}
                        logout          =   {this.props.logout}
                        dashboardUrl    =   {dashboardUrl}
                    />
                </Col>
            </Row>
        </Layout.Header>
    }
}

export const LoggedUserDetails = (props) => {
    let menu = <Menu>
                    <Menu.Item key="0">
                        <Icon type="user" />
                        <span style={{padding:"5px"}}>{props.name}</span>
                    </Menu.Item>
                    <Menu.Item key="1">
                        <Icon type="tags" />
                        <span style={{padding:"5px"}}>{props.email}</span>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Icon type="team" />
                        <span style={{padding:"5px"}}>{props.role}</span>
                    </Menu.Item>
                    <Menu.Divider />
                    {
                        props.isSearching &&
                            <Menu.Item key="3">
                                <Icon type="link" />
                                <span style={{padding:"10px"}}>
                                    <a target="_self" rel="noopener noreferrer" href={props.dashboardUrl}>Go To Dashboard</a>
                                </span>
                            </Menu.Item>
                    }
                    {
                        props.showSearchEngineLink &&
                            <Menu.Item key="/search">
                                <Icon type="search" />
                                <span style={{padding:"10px"}}>
                                    <a target="_self" rel="noopener noreferrer" href={"/search"}>Go To Search Engine</a>
                                </span>
                            </Menu.Item>
                    }
                    <Menu.Item key="4" onClick={props.logout}>
                        <Icon type="setting" />
                        <span style={{padding:"5px"}}>Logout</span>
                    </Menu.Item>
                </Menu>;
    return <Dropdown overlay={menu} placement="bottomCenter">
             <div className="user-detail pull-right" style={{cursor:"pointer"}}>
                <Avatar
                    style   =   {{margin: "0 5px"}}
                    src     =   {props.thumb}
                    alt     =   {props.name}
                    title   =   {props.name}
                    size    =   {32}
                    icon    =   "user"
                />
                <span className="user-welcome f-12">Welcome</span>
                <div className="bold user-name f-14" style={{ right: '50px'}}>{props.name || 'Guest'}</div>
             </div>
        </Dropdown>
}

const DashboardButton = (props) => {
    if(props.isSearching){
        return null;
    }
    return <Button type="primary-outline">
        <a target="_self" rel="noopener noreferrer" href={props.dashboardUrl}>Go To Dashboard</a>
    </Button>
}