import React from 'react';

export class TextFileContainer extends React.Component{

    render(){
        let {fileResult} = this.props;
        return(
            <React.Fragment>
                <div className="word-count"><b>Word Count :</b> {fileResult.target.result.split(' ').length}</div>
                <div className="textFile">
                    <pre className="pre-wrap">{fileResult.target.result}</pre>
                </div>
            </React.Fragment>
        )
    }

}