import React from 'react';
import { Layout, Modal, message } from 'antd';
import LayoutHeader from "./layout_header";
import LayoutContent from "./layout_content";
import common_scripts from '../modules/common_functions/script';

export default class LayoutContainer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            search : {
                isSearching     :   false,
                searchText      :   "",
                searchFilter    :   "All",
                sortFilters     :   {
                    params : ''
                },
                searchParams    :   ""
            },
            loading         : false,
            user_details    : null,
            user_notes      : null,
            email           : null,
            ms_details      : null,
            showModal       : false,
            showFilterDrawer: false,
            searchedData    : []
        }
    }

    componentDidMount(){
        this.initialSetting();
        // this.storageListeningEvent();
    }

    initialSetting = () => {
        let token_details = this.getTokenDetails();
        if(token_details){//check if token exists or not
            let validated_token = common_scripts.validateToken();
            if(!validated_token){
                Modal.error({
                    title: 'Error',
                    content: 'Your session has expired. Please enter your email to Login into the system.',
                    onOk : () => {
                        this.changeModalVisibility(true);
                        this.changeLoader(false);
                    }
                });
            }else{
                //login with given credentials and token
                this.microsoftLoginValidation({}, validated_token.sub);
            }
        }else{
            //Ask user to enter via microsoft login
            this.changeModalVisibility(true);
        }
    }

    storageListeningEvent = () => {
        console.log('Listening to storage changes');
        // window.addEventListener('storage', function(e) {
        //     console.log('Woohoo, someone changed my localstorage va another tab/window!');
        // });//sample code
        window.addEventListener('storage', this.storageChange);
    }

    storageChange = (e) => {
        console.log('changes in storage values')
        let newValue = e.newValue;
        let oldValue = e.oldValue;
        if(!newValue){//if new value is empty then logout
            this.logout();
        }
        else if(newValue && !oldValue){//if old value is empty and newvalue is present then login
            let newValueDetails = common_scripts.validateToken(newValue);
            this.microsoftLoginValidation({}, newValueDetails.sub);
        }
        else{//if both values exist then check for changes
            let newValueDetails = common_scripts.validateToken(newValue);
            let oldValueDetails = common_scripts.validateToken(oldValue);

            if((newValueDetails.sub !== oldValueDetails.sub) || (newValueDetails.id !== oldValueDetails.id) || (newValueDetails.role !== oldValueDetails.role)){
                this.microsoftLoginValidation({}, newValueDetails.sub);
            }
        }
    }

    microsoftLoginValidation = (ms, email) => {
        this.changeLoader(true);
        //now validate the user -- either create a user or get the user details by verifying the user
        let name = (ms.displayName && ms.displayName.trim !== "") ? ms.displayName : email.split('@')[0];
        let data = {
            data: {
                attributes : {
                    email,
                    name,
                    phone : ms.mobilePhone,
                    teams : ['5ahabmnjgoqnlvd'],//staging and live environment
                    role : '5ahagxjjgc35o3z'//staging and live environment
                },
                acc_id : '5ahagxjjgc3wk5z'//staging and live environment
            }
        };
        common_scripts.ms_login(data).then(res=>{
            console.log('microsoft login', res);
            if(res.data.success){
                this.setState({ms_details: ms, email});
                this.changeLoader(false);
                // localStorage.removeItem('jwt'); //remove token if old data is present
                localStorage.setItem('jwt', res.data.token);//set new token
                let validated_token = common_scripts.validateToken(res.data.token);
                this.getUserDetails(validated_token.id);
                this.getNotes();
                this.changeModalVisibility(false);
            }else{
                Modal.error({
                    title: 'Error',
                    content: res.data.message,
                    onOk : () => {
                        this.changeModalVisibility(true);
                        this.changeLoader(false);
                    }
                });
            }
        }).catch(error=>{
            Modal.error({
                title: 'Error',
                content: error.message
            });
            this.changeLoader(false);
        });
    }

    getUserDetails = (id, token = undefined) => {
        common_scripts.getOneUser(token, id).then(res=>{
            this.setState({user_details : res.data.data});
        }).catch(err=>{
            Modal.error({
                title: 'Error',
                content: 'Error getting user details.Please Try again.'
            });
        });
    }

    getNotes = () => {
        common_scripts.getNotes().then(res=>{
            this.setState({user_notes : res.data.data});
        }).catch(err=>{
            Modal.error({
                title: 'Error',
                content: 'Error getting Notes.Please Try again.'
            });
        });
    }

    getTokenDetails = () => {
        return localStorage.getItem('jwt');
    }

    onSearch = (e, category, filterParams, sortFilters) => {
        this.changeSearchText(e);
        if(e.trim() === ""){
            message.error('Please enter a value to search', 1);
            return;
        }
        this.setSearching(true);
        //make API call and show loader
        this.startSearching(category, filterParams, sortFilters);
    }

    showMessageModal = (type, title, message) => {
        Modal[type]({
            title : title,
            content : message
        });
    }

    startSearching = (category, filterParams = this.state.search.searchParams, sortFiltersParams = this.state.search.sortFilters.params) => {
        let filters = `type=file&text=${this.state.search.searchText}&file_tag=${this.state.search.searchText}&category=${category}${filterParams}${sortFiltersParams}`;
        this.changeLoader(true);
        common_scripts.search(undefined, filters).then(res=>{
            this.changeLoader(false);
            if(res.data.meta){
                this.setState({searchedData : res.data});
            }else{
                this.showMessageModal('error','Error', 'Error while searching data. Please try again');
            }
        }).catch(err=>{
            this.showMessageModal('error','Error', 'Error while searching data. Please try again');
            this.changeLoader(false);
        })
    }

    changeLoader = (val) => {
        this.setState({loading : val});
    }

    changeFilterDrawer = (val) => {
        this.setState({showFilterDrawer : val});
    }

    changeModalVisibility = (val) => {
        this.setState({showModal : val});
    }

    changeFilterCategory = (type, filters = this.state.search.searchParams, sortFilters = this.state.search.sortFilters.params) => {
        if(type === "More"){
            return this.changeFilterDrawer(true);
        }
        let search = this.state.search;
        search.searchFilter = type;
        search.searchParams = filters;
        search.sortFilters.params  = sortFilters;
        this.setState({search});
        if(search.searchText && search.searchText.trim() !== ""){
            let category = common_scripts.getFilterCategory(type);
            this.onSearch(search.searchText, category, filters, sortFilters);
        }
    }

    setSearching = (val) => {
        let search = this.state.search;
        search.isSearching = val;
        this.setState({search});
    }

    changeSearchText = (val) => {
        let search = this.state.search;
        search.searchText = val;
        this.setState({search});
    }

    mergeSearchData = (data, meta) => {
        let newData         = JSON.parse(JSON.stringify(data));
        let searchedData    = JSON.parse(JSON.stringify(this.state.searchedData));

        //merging two data's with unique values
        let newSearchedData;
        newSearchedData = newData.filter(data1 => {
            return !searchedData.data.some( data2 => {
                return data1.id === data2.id;
            });
        });
        newSearchedData.forEach(data=>{
            searchedData.data.push(data);
        });

        //incrementing meta count
        let searchedDataMeta    = searchedData.meta;
        searchedDataMeta.count  = searchedData.data.length;
        searchedDataMeta.totalCount  = meta.totalCount;

        searchedData.meta = searchedDataMeta;
        this.setState({searchedData});
    }

    emailLoginValidation = (email, password) => {
        this.changeLoader(true);
        common_scripts.login('user', email, password).then(res=>{
            if(res.data.success){
                this.setState({email});
                this.changeLoader(false);
                // localStorage.removeItem('jwt'); //remove token if old data is present
                localStorage.setItem('jwt', res.data.token);//set new token
                let validated_token = common_scripts.validateToken();
                this.getUserDetails(validated_token.id);
                this.getNotes();
                this.changeModalVisibility(false);
            }else{
                Modal.error({
                    title: 'Error',
                    content: res.data.message,
                    onOk : () => {
                        this.changeModalVisibility(true);
                        this.changeLoader(false);
                    }
                });
            }
        }).catch(error=>{
            Modal.error({
                title: 'Error',
                content: error.message
            });
            this.changeLoader(false);
        });
    }

    logout = () => {
        console.log('logging out of system')
        localStorage.removeItem('jwt');
        message.info('You have been successfully logged out of the system. Please login again to continue.', 3);
        this.setState({
            search : {
                isSearching : false,
                searchText  : "",
                searchFilter: "All",
                searchParams : "",
                sortFilters : {
                    params : ""
                }
            },
            loading         : false,
            user_details    : null,
            email           : null,
            ms_details      : null,
            showModal       : true,
            showFilterDrawer: false,
            searchedData    : []
        });

        let body = document.getElementsByTagName('body');
        body[0].className = "bg-img";
    }

    render() {
        let padding = !this.state.search.isSearching ? {padding : "24px 16px"} : {};
        return (
            <Layout className="transparent-background" style={{...padding, height:"inherit"}}>
                <LayoutHeader
                    userEmail           =   {this.state.email}
                    search              =   {this.state.search}
                    user                =   {this.state.user_details}
                    fixedHeader         =   {this.state.search.isSearching ? "fixedHeader" : ""}
                    onSearch            =   {this.onSearch}
                    changeFilter        =   {this.changeFilterCategory}
                    setSearching        =   {this.setSearching}
                    changeSearchText    =   {this.changeSearchText}
                    showMessageModal    =   {this.showMessageModal}
                    logout              =   {this.logout}
                />
                <LayoutContent
                    userEmail                   =   {this.state.email}
                    user_details                =   {this.state.user_details}
                    user_notes                  =   {this.state.user_notes}
                    search                      =   {this.state.search}
                    searchedData                =   {this.state.searchedData}
                    loading                     =   {this.state.loading}
                    showModal                   =   {this.state.showModal}
                    showFilterDrawer            =   {this.state.showFilterDrawer}
                    changeModalVisibility       =   {this.changeModalVisibility}
                    microsoftLoginValidation    =   {this.microsoftLoginValidation}
                    emailLoginValidation        =   {this.emailLoginValidation}
                    onSearch                    =   {this.onSearch}
                    changeFilter                =   {this.changeFilterCategory}
                    setSearching                =   {this.setSearching}
                    changeSearchText            =   {this.changeSearchText}
                    showMessageModal            =   {this.showMessageModal}
                    mergeSearchData             =   {this.mergeSearchData}
                    changeFilterDrawer          =   {this.changeFilterDrawer}
                />
            </Layout>
        );
    }
}